import React, { useState, useEffect, useRef } from "react";
import { Image, Input, Button } from "@nextui-org/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { XCircle, X, ArrowLeft } from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { saveTenderSuccess } from "../redux/actions/tenderActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateTender } from "../redux/actions/tenderActions";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./Newtender.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditTender() {
  const dispatch = useDispatch();
  const [inputRows, setInputRows] = useState([{}]);
  const navigate = useNavigate();
  const { tenderId, tendertype } = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);
  const token = localStorage.getItem("token");

  const bottomScroll = useRef(null)

  //console.log("ssssssss",tendertype)
  const editTenderLots = async (tenderId, lots, dispatch, token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Map through lots and update each one
      const updatePromises = lots.map(async (lot) => {
        const { lotId } = lot;

        // Make API request to update lot details
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/tender/lots/${lotId}`,
          lot,
          { headers }
        );

        // Assuming that response.data.lot contains the updated lot details
        dispatch(updateTender({ ...tenderId, lots: response.data.lot }));
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      console.log("Lots updated successfully");
    } catch (error) {
      console.error("Error updating lots:", error);
    }
  };
  const formik = useFormik({
    initialValues: {
      tenderName: "",
      startDate: "",
      endDate: "",
      lots: inputRows,
    },
    validationSchema: Yup.object().shape({
      tenderName: Yup.string().required("Tender name is required."),
      startDate: Yup.string().required("Start date is required."),
      endDate: Yup.string().required("End date is required."),
      lots: Yup.array().of(
        Yup.object().shape({
          lot_num: Yup.string().required("Lot number is required."),
          description: Yup.string().required("Description is required."),
          total_carat: Yup.number().required("Total carat is required."),
        })
      ),
    }),

    onSubmit: async (values) => {
      try {

        setSubmitLoading(true);
        const lots = values.lots.map((row) => ({
          lot_num: row.lot_num || "",
          description: row.description || "",
          total_carat: row.total_carat || "",
          no_stone: row.no_stone || "",
          size: row.size || "",
          _id: row._id || null,
        }));

        const tenderData = {
          startDate: values.startDate,
          endDate: values.endDate,
          name: values.tenderName,
          lots,
        };

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Make API request to update the main tender details
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/tender/${tenderId}`,
          tenderData,
          { headers }
        );
        dispatch(saveTenderSuccess(response.data));
        toast.success('Tender Updated successfully');
        // Call editTenderLots to update lots
        if (tendertype.toLowerCase() !== 'active' || tendertype.toLowerCase() !== 'expired') {
          navigate(`/open-tender/${tendertype.toLowerCase()}`);

        }
        navigate("/open-tender/all");
      } catch (error) {
        console.error("Error upating tender:", error);
        toast.error(error?.response?.data?.message || "Error updating tender");
      } finally {
        setSubmitLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchTenderDetails = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tender/lots/${tenderId}`,
          { headers }
        );

        let { startDate, endDate, name, lots } = response.data;
        lots = lots.sort((a, b) => a.lot_num.localeCompare(b.lot_num));
        lots = lots.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num));

        const startDateObj = startDate ? new Date(startDate) : new Date();
        const endDateObj = endDate ? new Date(endDate) : new Date();

        formik.setValues({
          tenderName: name,
          startDate: startDateObj,
          endDate: endDateObj,
          lots: lots,
        });
      } catch (error) {
        console.error("Error fetching tender details:", error);
      }
    };

    fetchTenderDetails();
  }, []);

  const addLotDetail = () => {
    const updatedLots = [...formik.values.lots, {}];
    setInputRows(updatedLots);
    formik.setFieldValue("lots", updatedLots);

    bottomScroll?.current?.scrollIntoView({ behavior: "smooth" })
  };

  const removeRow = (index) => {
    // Create a new array with the lot at the specified index removed
    const updatedLots = formik.values.lots.filter((_, i) => i !== index);
    // Update Formik's state with the new array
    formik.setFieldValue("lots", updatedLots, true); // true for shouldValidate
  };

  return (
    <>
      <div>
        <div className="body-conetnt-header bg-grayseven border-b border-mutedfour">
          <div className="flex items-center justify-between gap-5">
            <div className="flex items-center gap-7">
              <Link
              className="back_arrow"
                to={
                  tendertype.toLowerCase() !== "active" &&
                    tendertype.toLowerCase() !== "expired"
                    ? `/open-tender/${tendertype.toLowerCase()}`
                    : "/open-tender/all"
                }
              >
                <ArrowLeft size={24} weight="bold" />
              </Link>
              <h5 className="text-xl leading-7 text-blackone font-semibold">
                Edit tender
              </h5>
            </div>
            <Link to={
              tendertype.toLowerCase() !== "active" &&
                tendertype.toLowerCase() !== "expired"
                ? `/open-tender/${tendertype.toLowerCase()}`
                : "/open-tender/all"
            } className="cancle_arrow">
              <X size={24} color="#7e7e7e" weight="bold" />
            </Link>
          </div>
        </div>
        <div className="mb-24">
          <div className="lg:pb-7.5 lg:px-7.5  px-5 pt-7.5 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-5.5 new-tender-content"
            >
              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">Tender Name*</label>
                <Input
                  className="py-0 tender-name"
                  clearable
                  classNames={{
                    input: ["bg-transparent", "text-blackone"],
                    innerWrapper: "bg-transparent ",
                    inputWrapper: [
                      "bg-transparent border border border-mutedseven shadow-none py-0",
                      "md:h-11 h-10",
                      "!cursor-text",
                    ],
                  }}
                  type="text"
                  name="tenderName"
                  onChange={formik.handleChange}
                  value={formik.values.tenderName}
                />
                {formik.touched.tenderName && formik.errors.tenderName && (
                  <div className="text-red-500">{formik.errors.tenderName}</div>
                )}
              </div>
              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">Start Date*</label>
                <div className="relative">
                  {/* <Input
                    value={formik.values.startDate}
                    readOnly
                    className="py-0 dates"
                    classNames={{
                      input: [
                        "bg-transparent py-0 px-4",
                        "text-blackone",
                        "flex-row-reverse gap-3",
                      ],
                      innerWrapper: "bg-transparent",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none p-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    startContent={
                      <Image
                        alt="Calender Image Icon"
                        src="/header-img/icons/calendar.svg"
                        className="rounded-none ps-4 z-0"
                      />
                    }
                    type="text"
                    name="startDate"
                  /> */}
                  {/* <Input
                    className=" absolute top-0 bottom-0 start-0 end-0 opacity-0"
                    onChange={(event) => {
                      formik.handleChange(event);
                      formik.setFieldValue("startDate", event.target.value);
                    }}
                    value={formik.values.startDate}
                    clearable
                    classNames={{
                      input: ["px-4 ", "flex-row-reverse gap-3"],
                      inputWrapper: [
                        "bg-transparent md:h-11 h-10 border border border-mutedseven shadow-none p-0",
                      ],
                    }}
                    type="date"
                  /> */}

                  <div className=" relative h-11">
                    <div className="">
                      <Datetime
                        value={formik.values.startDate}
                        className="dates"
                        onChange={(event) => {
                          console.log(event);
                          //formik.handleChange(event?._d);
                          formik.setFieldValue("startDate", event?._d);
                        }}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm:ss a"
                        inputProps={{
                          placeholder: "",
                        }}
                      />
                    </div>
                    <div className="calender-img">
                      <Image
                        alt="Calender Image Icon"
                        src="/header-img/icons/calendar.svg"
                        className="rounded-none z-0 cursor-pointer "
                      />
                    </div>
                  </div>
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className="text-red-500">
                      {formik.errors.startDate}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">End Date*</label>
                <div className="relative h-11">
                  {/* <Input
                    value={formik.values.endDate}
                    readOnly
                    className="py-0 dates"
                    classNames={{
                      input: [
                        "bg-transparent py-0 px-4",
                        "text-blackone",
                        "flex-row-reverse gap-3",
                      ],
                      innerWrapper: "bg-transparent",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none p-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    startContent={
                      <Image
                        alt="Calender Image Icon"
                        src="/header-img/icons/calendar.svg"
                        className="rounded-none ps-4 z-0"
                      />
                    }
                    type="text"
                    name="endDate"
                  /> */}

                  {/* <div>{formik.values.endDate}</div> */}
                  {/* <Input
                    className=" absolute top-0 bottom-0 start-0 end-0 opacity-0"
                    onChange={(event) => {
                      formik.handleChange(event);
                      formik.setFieldValue("endDate", event.target.value);
                    }}
                    value={formik.values.endDate}
                    clearable
                    classNames={{
                      input: ["px-4", "flex-row-reverse gap-3"],
                      inputWrapper: [
                        "bg-transparent md:h-11 h-10 border border border-mutedseven shadow-none p-0",
                      ],
                    }}
                    type="datetime-local"
                  /> */}


                  <div className=" relative h-11">
                    <div className="">
                      <Datetime
                        value={formik.values.endDate}
                        className="dates"
                        onChange={(event) => {
                          console.log(event);
                          //formik.handleChange(event?._d);
                          formik.setFieldValue("endDate", event?._d);
                        }}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm:ss a"
                        inputProps={{
                          placeholder: "",
                        }}
                      />
                    </div>
                    <div className="calender-img">
                      <Image
                        alt="Calender Image Icon"
                        src="/header-img/icons/calendar.svg"
                        className="rounded-none z-0 cursor-pointer "
                      />
                    </div>
                  </div>

                  {formik.touched.endDate && formik.errors.endDate && (
                    <div className="text-red-500">{formik.errors.endDate}</div>
                  )}
                </div>
              </div>
              <div className="border border-mutedfour my-10 divider"></div>
              <div>
                <h4 className="text-blackone font-semibold leading-6.25 text-4.5">
                  Add Lot Detail
                </h4>
                <div className="border border-blue mt-3 max-w-40"></div>
              </div>
              <div className=" overflow-x-scroll">
                <div className="form_responsive ">
                  <div className="mt-6 flex items-center gap-3 lg:px-7.5 px-5 lotdetails">
                    <p className="comansize">LOT Number</p>
                    <p className="bigsize">Description</p>
                    <p className="comansize">Total Carat</p>
                    <p className="comansize">No. of Stone</p>
                    <p className="comansize">Size</p>
                  </div>
                  <div className="border border-mutedfour my-4"></div>
                  <div className="lg:px-7.5 px-5 flex flex-col gap-4">
                    {formik.values.lots.map((row, index) => (
                      <div key={index} className="flex  gap-3 addlotdetails">
                        <div className="comaninputs z-0">
                          {/* <div style={{ display: "none" }}>
                            <Input
                             
                              name={`lots[${index}]._id`}
                              {...formik.getFieldProps(
                                `lots[${index}]._id`
                              )}
                              className="py-0 "
                              clearable
                              
                              classNames={{
                                input: [
                                  "bg-transparent",
                                  "text-blackone",
                                  "d-none",
                                ],
                                innerWrapper: "bg-transparent d-none",
                                inputWrapper: [
                                  "bg-transparent border border border-mutedseven shadow-none py-0 d-none",
                                  "md:h-11 h-10",
                                  "!cursor-text",
                                ],
                              }}
                              type="number"

                            />
                          </div> */}
                          <Input
                            index={index}
                            name={`lots[${index}].lot_num`}
                            {...formik.getFieldProps(`lots[${index}].lot_num`)}
                            className="py-0 "
                            clearable
                            autoFocus={index === formik.values.lots.length - 1}
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.lot_num &&
                            formik?.errors?.lots[index]?.lot_num && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].lot_num}
                              </div>
                            )}
                        </div>
                        <div className="biginputs z-0">
                          <Input
                            name={`lots[${index}].description`}
                            {...formik.getFieldProps(
                              `lots[${index}].description`
                            )}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="text"
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.description &&
                            formik?.errors?.lots[index]?.description && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].description}
                              </div>
                            )}
                        </div>
                        <div className="comaninputs z-0">
                          <Input
                            name={`lots[${index}].total_carat`}
                            {...formik.getFieldProps(
                              `lots[${index}].total_carat`
                            )}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.total_carat &&
                            formik?.errors?.lots[index]?.total_carat && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].total_carat}
                              </div>
                            )}
                        </div>
                        <div className="comaninputs z-0">
                          <Input
                            name={`lots[${index}].no_stone`}
                            {...formik.getFieldProps(`lots[${index}].no_stone`)}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />

                        </div>
                        <div className="comaninputs z-0">
                          <Input
                            name={`lots[${index}].size`}
                            {...formik.getFieldProps(`lots[${index}].size`)}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />

                        </div>
                        <div
                          className={index === 0 ? "hidden" : "cursor-pointer"}
                        >
                          <div className="flex items-center h-11">
                            <XCircle
                              className="ms-2"
                              size={23}
                              color="#ff9c9c"
                              weight="bold"
                              onClick={() => removeRow(index)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div onClick={addLotDetail} className="addlotdetail_btn pb-4">
                      + Add Lot Detail
                    </div>
                    <div ref={bottomScroll}></div>
                  </div>
                </div>
              </div>
              <div className="tender-foot">
                <div className="lg:px-7.5 px-5 py-5.5 flex items-center justify-end gap-4">
                  <Button className="cancletender_btn">
                    <Link to={
                      tendertype.toLowerCase() !== "active" &&
                        tendertype.toLowerCase() !== "expired"
                        ? `/open-tender/${tendertype.toLowerCase()}`
                        : "/open-tender/all"
                    }>Cancel</Link>
                  </Button>
                  <Button
                    className="savetender_btn"
                    type="submit"
                    onClick={editTenderLots}
                    isDisabled={submitLoading}
                  >
                    <div>Update</div>
                    {submitLoading && (
                      <div className="small-loader extra-small-loader ms-4"></div>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
