// OpenTendersTable.js
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Link,
  useNavigate
} from "react-router-dom";
import { setTenders } from "../../redux/actions/tenderActions";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Pagination
} from "@nextui-org/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Changepassword from "../../components/Changepassword";
import ConfirmationTenderModal from "../../components/ConfirmationTenderModal";
import { updateTender } from "../../redux/actions/tenderActions";

const OpenTendersTable = ({ tenders, handleSetTender, tendertype }) => {

  const pageKey = localStorage.getItem('pageKey')
  const pagnub = localStorage.getItem(pageKey === 'tender' ? 'page' : 1)
  const pagRow = localStorage.getItem(pageKey === 'tender' ? 'pageRow' : 50)
  console.log("tenders", typeof (pagnub));
  // pegination
  //console.log("Tender : ", tenders)
  const [page, setPage] = useState(parseInt(pagnub));
  console.log("tenders", typeof (page));

  const [rowsPerPage, setRowsPerPage] = useState(pagRow);
  const pages = tendertype === 'ACTIVE' || tendertype === 'EXPIRED' ? Math.ceil(tenders
    .filter(
      (t) =>
        t.status === "ACTIVE" ||
        t.status === "EXPIRED"
    ).length / rowsPerPage) : Math.ceil(tenders.length / rowsPerPage);

  // console.log("fncsdjikfbnhsdju : ", pages);


  const [loading, setLoading] = useState(false);

  const topScroll = useRef(sessionStorage.getItem('scrollPosition') ? sessionStorage.getItem('scrollPosition') : null)

  useEffect(() => {

    // console.log("bxasxhsaxcvajvb : ", pageKey);


    if (pageKey !== 'tender') {
      localStorage.setItem('pageKey', 'tender')
      setPage(1)
      setRowsPerPage(50)
    }
  }, []);

  // useEffect(() => {
  //   localStorage.setItem('pageKey', 'customer');
  // },[pageKey])

  useEffect(() => {
    // console.log("Use State : ", page);
    localStorage.setItem('page', page && page != 1 ? page : 1);


  }, [page])
  useEffect(() => {
    // console.log("Use State Row Per Pages : ", rowsPerPage);
    localStorage.setItem('pageRow', rowsPerPage && rowsPerPage != 50 ? rowsPerPage : 50);
  }, [rowsPerPage])

  // Routes
  const stat = {
    ACTIVE: "active",
    EXPIRED: "expired",
    INQUEUE: "inqueue",
    CLOSED: "closed",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleUpdateTender = async (tender) => {
    try {
      navigate(
        `/open-tender/${tender.status.toLowerCase()}/edit-tender/${tender._id}`
      );
    } catch (error) {
      // Handle API call errors (e.g., display an error message)
      console.error("Error updating tender", error);
      toast.error(error?.response?.data?.message || "Error updating tender. Please try again.");
    }
  };


  // When the tender list component mounts or updates
  // useEffect(() => {
  //   // Retrieve the saved scroll position from the sessionStorage
  //   const savedScrollPos = sessionStorage.getItem('scrollPosition');

  //   if (savedScrollPos) {
  //     // Use the ref to access the scrollable container and set its scrollTop value
  //     topScroll.current.scrollTop = parseInt(savedScrollPos, 10);

  //     // Optionally, you can clear the saved position if you don't need it anymore
  //     sessionStorage.removeItem('scrollPosition');
  //   }
  // }, []);



  // const editTenderLots = async (tenderId, lots) => {
  //   try {
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //     };

  //     // Map through lots and update each one
  //     const updatePromises = lots.map(async (lot) => {
  //       const { lotId } = lot;

  //       // Make API request to update lot details
  //       const response = await axios.put(
  //         `${process.env.REACT_APP_API_URL}/tender/lots/${lotId}`,
  //         lot,
  //         { headers }
  //       );

  //       // Assuming that response.data.lot contains the updated lot details
  //       dispatch(updateTender({ _id: tenderId, lots: response.data.lot }));
  //     });

  //     // Wait for all updates to complete
  //     await Promise.all(updatePromises);

  //     console.log("Lots updated successfully");
  //     toast.success("Lots updated successfully");
  //   } catch (error) {
  //     console.error("Error updating lots:", error);
  //     toast.error(error?.response?.data?.message || "Error updating lots");
  //   }
  // };



  const fetchTenders = async (tenderType) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/${tenderType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedTenders = response.data;
      handleSetTender(fetchedTenders);
      dispatch(setTenders(fetchedTenders));
    } catch (error) {
      console.error("Error fetching tenders:", error);
    } finally {
      setLoading(false);
    }
  };

  const type = tendertype;

  const handleDeleteTender = async (tender) => {
    try {
      const token = localStorage.getItem("token");

      // Make a DELETE request to delete the tender
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_API_URL}/tender/delete/${tender._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTenders(type);
      toast.success("Tender, lots, and bids deleted successfully");
    } catch (error) {
      // Handle errors
      console.error("Error deleting tender", error);
      toast.error(error?.response?.data?.message || "Error deleting tender. Please try again.");
      // toast.error("Error deleting tender. Please try again.");
    }
  };

  const handleExpireTender = async (tender) => {
    try {
      const token = localStorage.getItem("token");

      // Make an API call to mark the tender as expired
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/tender/expired/${tender._id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response as needed (e.g., display a success message)
      // console.log("Tender marked as expired:", response.data);

      // Dispatch the Redux action to update the state
      dispatch(updateTender({ _id: tender._id, status: "EXPIRED" }));

      fetchTenders(type);
      toast.success("Tender expired successfully");
    } catch (error) {
      // Handle API call errors (e.g., display an error message)
      console.error("Error marking tender as expired:", error);
      toast.error(error?.response?.data?.message || "Error marking tender as expired");
    }
  };

  const handleMarkAsClosed = async (tender) => {
    // console.log("handleMarkAsClosed is being called");
    try {
      const token = localStorage.getItem("token");

      // Make an API call to mark the tender as closed
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/tender/closed/${tender._id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Tender successfully marked as closed");

      fetchTenders(type);
      // console.log('sj', response.data, fetchTenders);
      // await getItemsToResetAndUpdate(dispatch, fetchTenders, type);
    } catch (error) {
      // Handle API call errors (e.g., display an error message)
      console.error("Error marking tender as closed:", error);
      toast.error(error?.response?.data?.message || "Error marking tender as closed");
    }
  };

  // Table Pagination
  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };
  // useEffect(() => {
  //   setPage(1);
  // }, [rowsPerPage]);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;
  const tenderToShow =
    tendertype === "all"
      ? tenders
        .filter(
          (t) =>
            t.status === "ACTIVE" ||
            t.status === "EXPIRED"
        )
        .slice(startIndex, endIndex)
      : tenders.slice(startIndex, endIndex);


  // Moadals
  const [isModal2Open, setModal2Open] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedTender, setSelectedTender] = useState(null);
  const handleConfirmAction = async (action, tender) => {

    switch (action) {
      case "expire":
        await handleExpireTender(tender);
        break;
      case "delete":
        await handleDeleteTender(tender);
        break;
      case "close": // Ensure this matches the action string you set
        await handleMarkAsClosed(tender);
        break;
      // Add more cases as necessary
      default:
        console.error("Unknown action");
    }
    setIsModalOpen(false); // Close modal after action
  };

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className={`md:overflow-auto ${tendertype === 'closed' ? 'tablefixed' : ''} `}>
            <Table
              radius="none"
              removeWrapper
              // isHeaderSticky
              className="custom-table "
              // classNames={{
              //   base: "sm:max-h-full sm:overflow-scroll",
              //   table: "sm:min-h-full",
              // }}
              aria-label="Example static collection table"
            >
              <TableHeader>
                <TableColumn></TableColumn>
                <TableColumn>TENDER ID</TableColumn>
                <TableColumn>TENDER NAME</TableColumn>
                <TableColumn>START DATE</TableColumn>
                <TableColumn>END DATE</TableColumn>
                <TableColumn>REMAINING TIME</TableColumn>
                <TableColumn>STATUS</TableColumn>
                <TableColumn></TableColumn>
              </TableHeader>
              <TableBody>
                {tenderToShow.map((tender) => {
                  const viewtendres = (e) => {
                    // if(stat[tender.status] !== 'active'){
                    // console.log("Scroll:", topScroll);
                    // sessionStorage.setItem('scrollPosition', e.screenY);
                    navigate(
                      `/open-tender/${stat[tender.status]}/view-tender/${tender._id
                      }`
                    );
                    //}
                    //navigate(`/open-tender/view-tender/${tender._id}`);
                  };

                  // const viewtendres = () => {
                  //   // Ensure this line runs only when the user navigates away to the details page
                  //   if (topScroll && topScroll.current) {
                  //     // Save the current scroll position of the container and the tender id
                  //     const scrollDetails = {
                  //       scrollTop: topScroll.current.scrollTop,
                  //       tenderId: tender._id,
                  //     };
                  //     sessionStorage.setItem('scrollDetails', JSON.stringify(scrollDetails));
                  //   }

                  //   navigate(`/open-tender/${stat[tender.status]}/view-tender/${tender._id}`);
                  // };

                  return (
                    <TableRow
                      onClick={(e) => viewtendres(e)}
                      className=" cursor-pointer"
                      key={tender._id}
                    >
                      <TableCell className="">
                        <div className="w-4.5 z-0">
                          {tender.status === "ACTIVE" && (
                            <Image
                              alt="Selected Icon"
                              className="rounded-none transition-opacity duration-300 "
                              src="/box-img/blue-box.svg"
                            />
                          )}
                          {tender.status === "INQUEUE" && (
                            <Image
                              alt="Selected Icon"
                              className="rounded-none transition-opacity duration-300 "
                              src="/box-img/orange-box.svg"
                            />
                          )}
                          {tender.status === "EXPIRED" && (
                            <Image
                              alt="Selected Icon"
                              className="rounded-none transition-opacity duration-300 "
                              src="/box-img/red-box.svg"
                            />
                          )}
                          {tender.status === "CLOSED" && (
                            <Image
                              alt="Selected Icon"
                              className="rounded-none transition-opacity duration-300 "
                              src="/box-img/red-box.svg"
                            />
                          )}

                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{tender?.tenderId}</div>
                      </TableCell>
                      <TableCell>
                        <div>{tender.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {new Date(tender.startDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {new Date(tender.endDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className={`text-redtwo ${tender.status === "EXPIRED" ? "" : ""
                            }`}
                        >
                          {tender.status === "EXPIRED"
                            ? "--"
                            : tender.remainingTime}
                        </div>
                      </TableCell>
                      <TableCell>
                        {tender.status === "ACTIVE" && (
                          <div className="text-blue">{tender.status}</div>
                        )}
                        {tender.status === "INQUEUE" && (
                          <div className="text-orange">{tender.status}</div>
                        )}
                        {tender.status === "EXPIRED" && (
                          <div className="text-redtwo">{tender.status}</div>
                        )}
                        {tender.status === "CLOSED" && (
                          <div className="text-redtwo">{tender.status}</div>
                        )}
                        {/* <div className="text-redtwo ">{tender.status}</div>  */}
                      </TableCell>
                      <TableCell >
                        <div className="flex items-center justify-end">
                          <div className="block w-4.5 cursor-pointer z-0">
                            <Dropdown
                              placement="bottom-end"
                              radius="sm"
                              className="drop-box "
                              classNames={{ content: "p-0 shadow-drop " }}
                            >
                              <DropdownTrigger>
                                <Image
                                  alt="Edit Image Icon"
                                  src="/box-img/EditerToggle.svg"
                                  className="rounded-none z-0"
                                />
                              </DropdownTrigger>
                              <DropdownMenu className="z-10">
                                <DropdownSection className="mb-0  py-2">
                                  <DropdownItem className="p-0 rounded-none">
                                    <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                      <Link
                                        to=""
                                        className="text-sm leading-4.5.25 text-blackone"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleUpdateTender(tender);
                                        }}
                                      >
                                        Edit Tender
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem className="p-0 rounded-none ">
                                    <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                      <Link
                                        to={`/open-tender/${stat[tender.status]
                                          }/view-tender/${tender._id}`}
                                        className="text-sm leading-4.5.25 text-blackone"
                                      >
                                        View Tender
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem className="py-0 my-1">
                                    <div className="border p-0 border-wheat mx-1 cursor-default" ></div>
                                  </DropdownItem>
                                  {tender.status !== "EXPIRED" ? (
                                    <DropdownItem className="p-0 rounded-none">
                                      <div className="py-1.5 px-4 hover:bg-light-red rounded">
                                        <Link
                                          to=""
                                          className="text-sm leading-4.5.25 text-redone"
                                          onClick={() => {
                                            setSelectedAction("expire");
                                            setSelectedTender(tender);
                                            setIsModalOpen(true);
                                          }}
                                        >
                                          Expire Tender
                                        </Link>
                                      </div>
                                    </DropdownItem>
                                  ) : (
                                    <DropdownItem className="p-0 rounded-none">
                                      <div className="py-1.5 px-4 hover:bg-light-blue rounded ">
                                        <Link
                                          to=""
                                          className="text-sm leading-4.5.25 text-blue"
                                          //onClick={handleEditTenderClick(tender, {text : 'mark as close' })}
                                          onClick={() => {
                                            setSelectedAction("close");
                                            setSelectedTender(tender);
                                            setIsModalOpen(true);
                                          }}
                                        >
                                          Mark as Closed
                                        </Link>
                                      </div>
                                    </DropdownItem>
                                  )}
                                  {tender.status !== "EXPIRED" ? (
                                    <DropdownItem className="p-0 rounded-none">
                                      <div className="py-1.5 px-4 hover:bg-light-red rounded">
                                        <Link
                                          to=""
                                          className="text-sm leading-4.5.25 text-darkred"
                                          onClick={() => {
                                            setSelectedAction("delete");
                                            setSelectedTender(tender);
                                            setIsModalOpen(true);
                                          }}
                                        >
                                          Delete Tender
                                        </Link>
                                      </div>
                                    </DropdownItem>
                                  ) : (
                                    <DropdownItem className="hidden"></DropdownItem>
                                  )}
                                </DropdownSection>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>



          {tendertype === 'closed' ? <div className="lg:px-7.5 px-5 paginations">
            <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
              <div className="flex items-center gap-2">
                <p className="text-darkone text-sm leading-4.5.25 font-medium">
                  Page size
                </p>
                <select className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer" onChange={handlePageSizeChange} value={rowsPerPage}>
                  <option value="50">50 </option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
              <div className="closetd-pagi">
                <Pagination
                  // isCompact
                  showControls
                  total={pages || 1}
                  page={page}
                  // color="warning"
                  classNames={{
                    wrapper: "rounded ",
                    item: "rounded ",
                    cursor:
                      "bg-dark-blue  text-white rounded",
                  }}

                  initialPage={1}
                  onChange={(newPage) => {
                    setPage(newPage);
                    topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                />
              </div>
            </div>
          </div> : null}
        </>
      )}
      {/* Modlas */}
      <ConfirmationTenderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        action={selectedAction}
        currentTender={selectedTender}
        confirmStatusChange={() =>
          handleConfirmAction(selectedAction, selectedTender)
        }
      />
    </div>
  );
};

export default OpenTendersTable;
