import { configureStore } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import rootReducer from '../redux/reducers/rootReducer';

const store = configureStore({
  reducer: {
    rootReducer,
    // Add more reducers if needed
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
