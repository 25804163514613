import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Image, Switch, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Pagination } from "@nextui-org/react";
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight, Rows, } from "@phosphor-icons/react";
import React, { useEffect,useMemo, useState, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Changepassword from "../components/Changepassword";
import Confirmationmodal from "../components/Confirmationmodal";
import Searchinput from "../components/Searchinput";
import "./Needapproval.scss";
import { CustomerUpdateContext } from "../components/Sidebar";
import { locale } from "moment";

export default function NeedApproval() {
  // pegination

  const pageKey = localStorage.getItem('pageKey')

  const pagnub = localStorage.getItem(pageKey === 'customer' ? 'page' : 1)
  const pagRow = localStorage.getItem(pageKey === 'customer' ? 'pageRow' : 50)

  console.log("Page Number : ", pagnub);
  const [page, setPage] = useState(parseInt(pagnub));
  console.log("Page : ", page);
  const [rowsPerPage, setRowsPerPage] = useState(pagRow);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState({});
  const { status } = useParams();

  // Modals
  const [isModal1Open, setModal1Open] = useState(false);
  const [isModal2Open, setModal2Open] = useState(false);

  //Context
  const { isUpdated, setIsUpdated } = useContext(CustomerUpdateContext);
  const [selectedAction, setSelectedAction] = useState("");
  const topScroll = useRef(null)

  useEffect(() => {
    if (pageKey !== 'customer') {
      localStorage.setItem('pageKey', 'customer')
      setPage(1)
      setRowsPerPage(50)
    }
  }, [])

  useEffect(() => {
    console.log("Use State : ", page);
    localStorage.setItem('page', page && page != 1 ? page : 1);


  }, [page])
  useEffect(() => {

    console.log("Use State Row Per Pages : ", rowsPerPage);
    localStorage.setItem('pageRow', rowsPerPage && rowsPerPage != 50 ? rowsPerPage : 50);

  }, [rowsPerPage])

  localStorage.getItem('page')
  useEffect(() => {
    fetchCustomers();
  }, [status]);

  const fetchCustomers = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomers?status=${status}`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCustomers(data);
      setLoading(false);
      //
    } catch (error) {
      console.error("Error fetching customers:", error);
      setLoading(false);
      toast.error(
        error?.response?.data?.message || "Error updating customer status"
      );
    }
  };

  const updateCustomerStatus = async (_id, status) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/updateCustomerStatus`;
      const token = localStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ _id, status }),
      });
      const data = await response.json();
      setIsUpdated(true);
      console.log("isUpdated : ", isUpdated);
      fetchCustomers();

      if (response.ok) {
        console.log(data.message);
        // Display toast based on the action
        switch (status) {
          case "approved":
            toast.success("Customer Approved Successfully");
            break;
          case "rejected":
            toast.success("Customer Rejected Successfully");
            break;
          case "deleted":
            toast.success("Customer Deleted Successfully");
            break;
          default:
            break;
        }
      } else {
        toast.error(
          error?.response?.data?.message || "Error updating customer status"
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error updating customer status"
      );
    }
  };
  const [currentCustomer, setCurrentCustomer] = useState({
    _id: "",
    status: "", // assuming the default status could be 'pending', 'approved', or 'rejected'
  });
  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const handleToggle = (customer, action) => {
    setSelectedAction(action);
    setCurrentCustomer(customer);
    setModal1Open(true);
  };
  const confirmStatusChange = async (newStatus) => {
    await updateCustomerStatus(currentCustomer._id, newStatus);
    setCurrentCustomer({ ...currentCustomer, status: newStatus });
    setModal1Open(false);
  };
  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  const endIndex = page * rowsPerPage;
  const customersToShow = searchTerm.trim()
    ? filteredCustomers?.filter((x) => x?.mobileNumber !== "9913131396")?.slice((page - 1) * rowsPerPage, endIndex)
    : customers?.filter((x) => x?.mobileNumber !== "9913131396")?.slice((page - 1) * rowsPerPage, endIndex);

    console.log("customersToShow : ", customersToShow);

  const pages = Math.ceil((searchTerm.trim() ? filteredCustomers.length: customers.length) / rowsPerPage);
  // const pages = useMemo(() => Math.ceil((searchTerm.trim() ? filteredCustomers.length || 0 : customers.length || 0) / rowsPerPage), [filteredCustomers, rowsPerPage, customers]) || 4;
  // Curomer Title Length
  const customerlength = filteredCustomers.length;
  // New-Tender
  const navigate = useNavigate();
  const navigateToNewCustomer = () => {
    navigate("/approved/newcustomer");
  };

  const handlecustomerClick = (customer) => {
    const customerpath = `/${customer.status}/view-active-customer/${customer._id}?status=open_bids`;

    navigate(customerpath);
  };
  const dropdownTriggerImages = {
    pending: "/box-img/orange-box.svg",
    approved: "/box-img/blue-box.svg",
    rejected: "/box-img/red-box.svg",
  };
  const titleBasedOnStatus = {
    pending: "Customers - Need Approval",
    approved: "Customers - Active",
    rejected: "Customers - Inactive",
    default: "Customers",
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCustomers(customers);
    } else {
      // Filter the tenders based on the search term
      const filtered = customers.filter(
        (customer) =>
          customer.customerName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          customer.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredCustomers(filtered);
    }
  }, [customers, searchTerm]);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header border-b-2 border-grayone md:sticky md:top-0 bg-white md:z-10">
            <div className="flex flex-wrap gap-5 items-center justify-between ">
              <div>
                <h5 className="text-lg leading-8.25 text-blackone font-semibold">
                  {titleBasedOnStatus[status]} ({customerlength})
                </h5>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap items-center gap-5">
                <Searchinput
                  customer={customers}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                {status === "approved" && (
                  <Button
                    onClick={navigateToNewCustomer}
                    className="min-h-9.5 rounded-2.5 px-0 min-w-35 add_btn "
                  >
                    <p className="leading-5.5 font-medium text-white">
                      Add Customer
                    </p>
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div ref={topScroll} className="md:overflow-auto pb-20">
            <Table
              // isHeaderSticky
              radius="none"
              removeWrapper
              className="custom-table"
              classNames={{
                base: "sm:max-h-full sm:overflow-scroll",
                table: "sm:min-h-full",
              }}
              aria-label="Example static collection table"
            >
              <TableHeader className="">
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>CONTACT PERSON</TableColumn>
                <TableColumn>COMPANY</TableColumn>
                <TableColumn>PHONE</TableColumn>
                <TableColumn>VILLAGE</TableColumn>
                <TableColumn>DALAL NAME</TableColumn>
                <TableColumn>DALAL NUMBER</TableColumn>
                {status === "pending" ? (
                  <TableColumn className="hidden"></TableColumn>
                ) : (
                  <TableColumn>ACTIVE</TableColumn>
                )}
                <TableColumn></TableColumn>
              </TableHeader>
              <TableBody className="">
                {customersToShow
                  ?.filter((x) => x?.mobileNumber !== "9913131396")
                  ?.map((customer, index) => (
                    <TableRow
                      className="cursor-pointer "
                      onClick={() => handlecustomerClick(customer)}
                      key={customer._id}
                    >
                      <TableCell className="">
                        <div className="w-4.5 z-0">
                          <Dropdown
                            placement="bottom-start"
                            className="select-box  z-0"
                            radius="sm"
                            classNames={{
                              content:
                                "p-0 border border-graythree shadow-none ",
                            }}
                          >
                            <DropdownTrigger className="cursor-pointer">
                              <Image
                                alt="Select Icon"
                                className="rounded-none"
                                src={dropdownTriggerImages[status]}
                              />
                            </DropdownTrigger>
                            <DropdownMenu
                              className="pt-2.5 px-0.75 pb-1.25 rounded-1.25 z-0"
                              itemClasses={{
                                base: [
                                  "rounded-1.25",
                                  "data-[hover=true]:bg-default-100",
                                ],
                              }}
                            >
                              <DropdownSection
                                aria-label="Profile & Actions"
                                className="mb-0"
                              >
                                {(status == "pending" ||
                                  status == "rejected") && (
                                    <DropdownItem
                                      key="approve"
                                      className="p-2.75 bg-skyblue rounded-none mt-2"
                                      onClick={() =>
                                        handleToggle(customer, "approve")
                                      }
                                    >
                                      <Link
                                        to=""
                                        className="flex items-center gap-3"
                                      >
                                        <Image
                                          alt="Approve Image Icon"
                                          className="rounded-none"
                                          src="/box-img/blue-box.svg"
                                        />
                                        <p className="font-medium text-sm leading-4.5.25 text-blue ">
                                          Click to Approve
                                        </p>
                                      </Link>
                                    </DropdownItem>
                                  )}
                                {(status == "pending" ||
                                  status == "approved") && (
                                    <DropdownItem
                                      key="reject"
                                      className="  p-2.75 bg-light-red mt-1 rounded-none"
                                      onClick={() =>
                                        handleToggle(customer, "reject")
                                      }
                                    >
                                      <Link
                                        to=""
                                        className="flex items-center gap-3"
                                      >
                                        <Image
                                          alt="Reject Image Icon"
                                          className="rounded-none"
                                          src="/box-img/red-box.svg"
                                        />
                                        <p className="font-medium text-sm leading-4.5.25 text-red ">
                                          Click to Reject
                                        </p>
                                      </Link>
                                    </DropdownItem>
                                  )}
                                {status == "rejected" && (
                                  <DropdownItem
                                    key="delete"
                                    className="p-2.75 bg-light-red rounded-none mt-1"
                                    onClick={() =>
                                      handleToggle(customer, "approve")
                                    }
                                  >
                                    <Link
                                      to=""
                                      className="flex items-center gap-3"
                                    >
                                      <Image
                                        alt="Approve Image Icon"
                                        className="rounded-none"
                                        src="/box-img/red-box.svg"
                                      />
                                      <p className="font-medium text-sm leading-4.5.25 text-red ">
                                        Click to Delete
                                      </p>
                                    </Link>
                                  </DropdownItem>
                                )}
                              </DropdownSection>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </TableCell>
                      <TableCell>
                        {/* ----- Image Box ----- */}
                        <div className="z-0 w-8  rounded-1.5 user_img overflow-hidden">
                          {customer.profile_photo ? (
                            <Image
                              alt="Customer Images"
                              src={customer.profile_photo}
                              className=" rounded object-fill h-8 w-8"
                            />
                          ) : (
                            <div className="border-1 border-bluetwo text-bluetwo rounded-1.25 flex items-center justify-center text-sm uppercase font-semibold h-8 w-8">
                              {customer?.customerName?.charAt(0)}
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {customer.customerName} {customer.surname}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{customer.companyName}</div>
                      </TableCell>
                      <TableCell>
                        <div>{customer.mobileNumber}</div>
                      </TableCell>
                      <TableCell>
                        <div>{customer.villageName}</div>
                      </TableCell>
                      <TableCell>
                        <div>{customer.agentName}</div>
                      </TableCell>
                      <TableCell>
                        <div>{customer.agentMobile}</div>
                      </TableCell>
                      {status === "pending" ? (
                        <TableCell className="hidden"></TableCell>
                      ) : (
                        <TableCell>
                          <div className="switch-toggle z-0">
                            {/* <Switch onClick={onOpen} size="sm" /> */}
                            <Switch
                              isSelected={status === "approved"}
                              onClick={() =>
                                handleToggle(
                                  customer,
                                  status === "approved" ? "reject" : "approve"
                                )
                              }
                              size="sm"
                            />
                          </div>
                        </TableCell>
                      )}
                      <TableCell className="z-0">
                        {/* Active Satus DropDown */}
                        <div className="flex items-center z-0 h-full justify-end custom-drop">
                          <Dropdown
                            placement="bottom-end"
                            radius="sm"
                            className="drop-box"
                            classNames={{ content: "p-0 shadow-drop " }}
                          >
                            <DropdownTrigger>
                              <Image
                                alt="Edit Image Icon"
                                src="/box-img/EditerToggle.svg"
                                className="rounded-none cursor-pointer"
                              />
                            </DropdownTrigger>
                            <DropdownMenu className="z-10">
                              <DropdownSection className="mb-0 py-2">
                                <DropdownItem className="p-0 rounded-none">
                                  <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                    <Link
                                      to={`/${customer?.status}/editcustomer/${customer._id}`}
                                      className="text-sm leading-4.5.25 text-blackone"
                                    >
                                      Edit Customer
                                    </Link>
                                  </div>
                                </DropdownItem>
                                <DropdownItem className="p-0 rounded-none">
                                  <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                    <Link
                                      to={`/${customer.status}/view-active-customer/${customer._id}?status=open_bids`}
                                      className="text-sm leading-4.5.25 text-blackone"
                                    >
                                      View Customer
                                    </Link>
                                  </div>
                                </DropdownItem>

                                <DropdownItem className=" p-0 rounded-none ">
                                  <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                    <Link
                                      to=""
                                      className="text-sm leading-4.5.25 text-blackone "
                                      onClick={() => {
                                        setCurrentCustomerId(customer._id);
                                        setModal2Open(true);
                                      }}
                                    >
                                      Change Password
                                    </Link>
                                  </div>
                                </DropdownItem>

                                <DropdownItem className="py-0 my-1">
                                  <div className="border p-0 border-wheat mx-1 cursor-default"></div>
                                </DropdownItem>

                                {status !== "approved" ? (
                                  <DropdownItem className="p-0 rounded-none">
                                    <div
                                      className="text-sm leading-4.5.25 text-blue py-1.5 px-4 hover:bg-light-blue rounded"
                                      onClick={() => {
                                        setSelectedAction("approve");
                                        setCurrentCustomer(customer);
                                        setModal1Open(true);
                                      }}
                                    >
                                      Approve Customer
                                    </div>
                                  </DropdownItem>
                                ) : null}
                                {status !== "rejected" ? (
                                  <DropdownItem className="p-0 rounded-none">
                                    <div
                                      className="text-sm leading-4.5.25 text-redone py-1.5 px-4 hover:bg-light-red rounded"
                                      onClick={() => {
                                        setSelectedAction("reject");
                                        setCurrentCustomer(customer);
                                        setModal1Open(true);
                                      }}
                                    >
                                      Inactive Customer
                                    </div>
                                  </DropdownItem>
                                ) : null}
                                <DropdownItem className="p-0 rounded-none ">
                                  <div
                                    className="text-sm leading-4.5.25 py-1.5 px-4 hover:bg-light-red text-darkred rounded"
                                    onClick={() => {
                                      setSelectedAction("delete");
                                      setCurrentCustomer(customer);
                                      setModal1Open(true);
                                    }}
                                  >
                                    Delete Customer
                                  </div>
                                </DropdownItem>
                              </DropdownSection>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>

          {customersToShow && customersToShow.length ? <div className="lg:px-7.5 px-5 paginations">
            <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
              <div className="flex items-center gap-2">
                <p className="text-darkone text-sm leading-4.5.25 font-medium">
                  Page size
                </p>
                <select
                  className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                  onChange={handlePageSizeChange}
                  value={rowsPerPage}
                >
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
              <Pagination
                // isCompact
                showControls
                total={pages}
                page={page}
                // color="warning"
                classNames={{
                  wrapper: "rounded ",
                  item: "rounded ",
                  cursor:
                    "bg-dark-blue  text-white rounded",
                }}

                initialPage={page}
                onChange={(newPage) => {
                  setPage(newPage);
                  topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
          </div> : null}
        </>
      )}
      {/* Modlas */}
      <Confirmationmodal
        isOpen={isModal1Open}
        onClose={() => setModal1Open(false)}
        selectedAction={selectedAction}
        currentCustomer={currentCustomer}
        confirmStatusChange={confirmStatusChange}
      />{" "}
      {/* Confirmation Modal */}
      <Changepassword
        isOpen={isModal2Open}
        onClose={() => setModal2Open(false)}
        currentCustomerId={currentCustomerId}
      />{" "}
      {/* Change Password Moadl */}
    </>
  );
}
