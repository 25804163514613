import React, { useState, useMemo, useCallback, useRef } from "react";
import { Image, Input, Button } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { XCircle, X, ArrowLeft } from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { saveTenderSuccess } from "../redux/actions/tenderActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Newtender.scss";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const MyInput = React.memo(({ field, index, ...props }) => {
  return <Input {...props.fieldProps} {...props} />;
});

export default function NewTender() {


  const [value, setValue] = React.useState('');


  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const bottomScroll = useRef(null);

  const initialValues = useMemo(
    () => ({
      tenderName: "",
      startDate: "",
      endDate: "",
      lots: [{}],
    }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      tenderName: Yup.string().required("Tender name is required."),
      startDate: Yup.string().required("Start date is required."),
      endDate: Yup.string().required("End date is required."),
      lots: Yup.array().of(
        Yup.object().shape({
          lot_num: Yup.string().required("Lot number is required."),
          description: Yup.string().required("Description is required."),
          total_carat: Yup.number().required("Total carat is required."),
        })
      ),
    }),

    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: false,
  });

  async function handleSubmit(values) {
    try {
      setSubmitLoading(true);

      const lots = values.lots.map((row) => ({
        lot_num: row.lot_num || "",
        description: row.description || "",
        total_carat: row.total_carat || "",
        no_stone: row.no_stone || "",
        size: row.size || "",
      }));

      const tenderData = {
        startDate: values.startDate,
        endDate: values.endDate,
        name: values.tenderName,
        lots,
      };
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tender`,
        tenderData,
        { headers }
      );

      // Dispatch success action
      dispatch(saveTenderSuccess(response.data));
      toast.success("Tender created successfully");
      setSubmitLoading(false);
      navigate("/open-tender/all");
    } catch (error) {
      console.error("Error creating tender:", error);
      toast.error(error?.response?.data?.message || "Error creating tender");
      setSubmitLoading(false);
    }
  }

  const addLotDetail = useCallback(() => {
    const updatedLots = [...formik.values.lots, {}];
    //setInputRows(updatedLots);
    formik.setFieldValue("lots", updatedLots);

    bottomScroll?.current?.scrollIntoView({ behavior: "smooth" });
  }, [formik]);

  const removeRow = useCallback(
    (index) => {
      const updatedLots = formik.values.lots.filter((_, i) => i !== index);
      formik.setFieldValue("lots", updatedLots);
    },
    [formik]
  );
  return (
    <>
      <div>
        <div className="body-conetnt-header bg-grayseven border-b border-mutedfour">
          <div className="flex items-center justify-between gap-5">
            <div className="flex items-center gap-7">
              <Link to="/open-tender/all" className="back_arrow">
                <ArrowLeft size={24} weight="bold" />
              </Link>
              <h5 className="text-xl leading-7 text-blackone font-semibold">
                Add New Tender
              </h5>
            </div>
            <Link to="/open-tender/all" className="cancle_arrow">
              <X size={24} color="#7e7e7e" weight="bold" />
            </Link>
          </div>
        </div>
        <div className="mb-24">
          <div className="lg:pb-7.5 lg:px-7.5  px-5 pt-7.5 ">
            <form
              onSubmit={formik.handleSubmit}
              action=""
              className="flex flex-col gap-5.5 new-tender-content"
            >
              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">Tender Name*</label>
                <Input
                  className="py-0 tender-name"
                  clearable
                  classNames={{
                    input: ["bg-transparent", "text-blackone"],
                    innerWrapper: "bg-transparent ",
                    inputWrapper: [
                      "bg-transparent border border border-mutedseven shadow-none py-0",
                      "md:h-11 h-10",
                      "!cursor-text",
                    ],
                  }}
                  type="text"
                  name="tenderName"
                  onChange={formik.handleChange}
                  value={formik.values.tenderName}
                />
                {formik.touched.tenderName && formik.errors.tenderName && (
                  <div className="text-red-500">{formik.errors.tenderName}</div>
                )}
              </div>
              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">Start Date*</label>
                <div className="relative cursor-pointer h-11">
                  {/* <div>
                    <Datetime
                      value={formik.values.startDate}
                      className="dates"
                      onChange={(event) => {
                        console.log(event._d);
                        // formik.handleChange(event);
                        formik.setFieldValue("startDate", event?._d);
                      }}
                      dateFormat="DD-MM-YYYY"
                      timeFormat="HH:mm:ss a"
                      inputProps={{
                        placeholder: "",
                      }}
                    />
                  </div>
                  <div className="calender-img">
                    <Image
                      alt="Calender Image Icon"
                      src="/header-img/icons/calendar.svg"
                      className="rounded-none z-0 cursor-pointer "
                    />
                  </div> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      // value={value}
                      // onChange={(newValue) => setValue(newValue)}
                      value={formik.values.startDate}
                      // className="dates"
                      onChange={(event) => {
                        console.log(event?.$d);
                        // formik.handleChange(event);
                        formik.setFieldValue("startDate", event?.$d);
                      }}
                      // dateFormat="DD-MM-YYYY"
                      // timeFormat="HH:mm:ss a"
                      // inputProps={{
                      //   placeholder: "",
                      // }}
                      format="DD-MM-YYYY hh:mm A"
                       
                    />
                  </LocalizationProvider>

                </div>
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="text-red-500">
                    {formik.errors.startDate}
                  </div>
                )}
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                <label className="whitespace-nowrap ">End Date*</label>
                <div className="relative cursor-pointer h-11">
                  {/* <div>
                    <Datetime
                      value={formik.values.endDate}
                      className="dates"
                      onChange={(event) => {
                        console.log(event._d);
                        // formik.handleChange(event);
                        formik.setFieldValue("endDate", event?._d);
                      }}
                      dateFormat="DD-MM-YYYY"
                      // timeFormat="HH:mm:ss a"
                      inputProps={{
                        placeholder: "",
                      }}
                    />
                  </div>
                  <div className="calender-img">
                    <Image
                      alt="Calender Image Icon"
                      src="/header-img/icons/calendar.svg"
                      className="rounded-none z-0 cursor-pointer "
                    />
                  </div> */}
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        // value={value}
                        // onChange={(newValue) => setValue(newValue)}
                        value={formik.values.endDate}
                        // className="dates"
                        onChange={(event) => {
                          console.log(event?.$d);
                          // formik.handleChange(event);
                          formik.setFieldValue("endDate", event?.$d);
                        }}
                        // dateFormat="DD-MM-YYYY"
                        // timeFormat="HH:mm:ss a"
                        // inputProps={{
                        //   placeholder: "",
                        // }}
                        format="DD-MM-YYYY hh:mm A"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className="text-red-500">
                    {formik.errors.endDate}
                  </div>
                )}
              </div>
              <div className="border border-mutedfour my-10 divider"></div>
              <div>
                <h4 className="text-blackone font-semibold leading-6.25 text-4.5">
                  Add Lot Detail
                </h4>
                <div className="border border-blue mt-3 max-w-40"></div>
              </div>
              <div className=" overflow-x-scroll">
                <div className="form_responsive ">
                  <div className="mt-6 flex items-center gap-3 lg:px-7.5 px-5 lotdetails">
                    <p className="comansize">LOT Number</p>
                    <p className="bigsize">Description</p>
                    <p className="comansize">Total Carat</p>
                    <p className="comansize">No. of Stone</p>
                    <p className="comansize">Size</p>
                  </div>
                  <div className="border border-mutedfour my-4"></div>
                  <div className="lg:px-7.5 px-5 flex flex-col gap-4">
                    {formik.values.lots.map((row, index) => (
                      <div key={index} className="flex  gap-3 addlotdetails">
                        <div className="comaninputs z-0">
                          <MyInput
                            index={index}
                            fieldProps={formik.getFieldProps(
                              `lots[${index}].lot_num`
                            )}
                            //name={`lots[${index}].lot_num`}
                            //{...formik.getFieldProps(`lots[${index}].lot_num`)}
                            className="py-0 "
                            clearable
                            autoFocus={index === formik.values.lots.length - 1}
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.lot_num &&
                            formik?.errors?.lots[index]?.lot_num && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].lot_num}
                              </div>
                            )}
                        </div>
                        <div className="biginputs z-0">
                          <MyInput
                            index={index}
                            fieldProps={formik.getFieldProps(
                              `lots[${index}].description`
                            )}
                            // name={`lots[${index}].description`}
                            // {...formik.getFieldProps(
                            //   `lots[${index}].description`
                            // )}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="text"
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.description &&
                            formik?.errors?.lots[index]?.description && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].description}
                              </div>
                            )}
                        </div>
                        <div className="comaninputs z-0">
                          <MyInput
                            index={index}
                            fieldProps={formik.getFieldProps(
                              `lots[${index}].total_carat`
                            )}
                            // name={`lots[${index}].total_carat`}
                            // {...formik.getFieldProps(
                            //   `lots[${index}].total_carat`
                            // )}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />
                          {formik?.touched?.lots &&
                            formik?.errors?.lots &&
                            formik?.touched?.lots[index]?.total_carat &&
                            formik?.errors?.lots[index]?.total_carat && (
                              <div className="text-red-500 ps-0.75">
                                {formik.errors.lots[index].description}
                              </div>
                            )}
                        </div>
                        <div className="comaninputs z-0">
                          <MyInput
                            index={index}
                            fieldProps={formik.getFieldProps(
                              `lots[${index}].no_stone`
                            )}
                            // name={`lots[${index}].no_stone`}
                            // {...formik.getFieldProps(`lots[${index}].no_stone`)}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />
                        </div>
                        <div className="comaninputs z-0">
                          <MyInput
                            index={index}
                            fieldProps={formik.getFieldProps(
                              `lots[${index}].size`
                            )}
                            // name={`lots[${index}].size`}
                            // {...formik.getFieldProps(`lots[${index}].size`)}
                            className="py-0 "
                            clearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone"],
                              innerWrapper: "bg-transparent ",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven shadow-none py-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="number"
                          />
                        </div>
                        <div
                          className={index === 0 ? "hidden" : "cursor-pointer"}
                        >
                          <div className="flex items-center h-11">
                            <XCircle
                              className="ms-2"
                              size={23}
                              color="#ff9c9c"
                              weight="bold"
                              onClick={() => removeRow(index)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      onClick={addLotDetail}
                      className="addlotdetail_btn pb-4"
                    >
                      + Add Lot Detail
                    </div>
                    <div ref={bottomScroll}></div>
                  </div>
                </div>
              </div>
              <div className="tender-foot">
                <div className="lg:px-7.5 px-5 py-5.5 flex items-center justify-end gap-4">
                  <Button className="cancletender_btn">
                    <Link to="/open-tender/all">Cancel</Link>
                  </Button>
                  <Button
                    className="savetender_btn"
                    type="submit"
                    isDisabled={submitLoading}
                  >
                    <div>Save</div>
                    {submitLoading && (
                      <div className="small-loader extra-small-loader ms-4"></div>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
