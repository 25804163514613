import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
  Pagination
} from "@nextui-org/react";
import {
  ArrowLeft,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CaretRight,
  Info,
  X,
} from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ConfirmationTenderModal from "../components/ConfirmationTenderModal";
import { setTenders, updateTender } from "../redux/actions/tenderActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Customerbriddetails from "./Customerbriddetails";

import axios from "axios";
export default function ViewTender() {
  // Customer Bid Modal
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { tenderId, tendertype } = useParams();
  const [tenderData, setTenderData] = useState({ lots: [] });
  const [selectedLot, setSelectedLot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exportloading, setExportLoading] = useState(false);
  const [wonBids, setWonBids] = useState([]);

  const topScroll = useRef(null);

  const savedPosition = sessionStorage.getItem('scrollPosition');
  console.log("Saved Session : ", savedPosition);

  // const lots = useSelector((state) => state.tenderReducer.activeTenders.lots);
  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString)
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace("am", "AM")
      .replace("pm", "PM");
    //const  = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() }`;

    return formattedDate;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tender/lots/${tenderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
          },
        }
      );

      const data = await response.json();
      //data = data.lots.sort((a, b) => a.lot_num.localeCompare(b.lot_num));
      setTenderData(data);
    } catch (error) {
      console.error("Error fetching tender data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTenders = async (tenderType) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/${tenderType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedTenders = response.data;
      //handleSetTender(fetchedTenders);
      dispatch(setTenders(fetchedTenders)); // Assuming you're using Redux and have a setTenders action
    } catch (error) {
      console.error("Error fetching tenders:", error);
    }
  };
  const editTenderLots = async (lotId) => {
    try {
      console.log("Fetching tender data...");
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tender/lots/${lotId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            status: "withdraw",
          }),
        }
      );

      toast.success("Tenders edited successfully");
      fetchData();
    } catch (error) {
      console.error("Error editing tender data:", error);
      toast.error(
        error?.response?.data?.message || "Error editing tender data"
      );
    }
  };
  useEffect(() => {
    fetchData();
  }, [tenderId]);

  const handlechangeSetWonBid = (value) => {
    setWonBids(value);
  };

  const getMaxBidValue = (bids) => {
    if (bids.length === 0) {
      return 0;
    }

    const maxBid = bids.reduce(
      (max, bid) => (bid.price > max ? bid.price : max),
      0
    );
    return maxBid;
  };

  const handleDeleteTender = async (tender) => {
    try {
      const token = localStorage.getItem("token");

      // Make a DELETE request to delete the tender
      const deleteResponse = await axios.delete(
        `${process.env.REACT_APP_API_URL}/tender/delete/${tender._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the delete response as needed
      console.log(
        "Tender, lots, and bids deleted successfully",
        deleteResponse.data
      );

      // Update tenders array in the component state.
      fetchTenders(deleteResponse.data.status);
      // await getItemsToResetAndUpdate(dispatch, fetchTenders, type);
      toast.success("Tender, lots, and bids deleted successfully");
      navigate(-1);
    } catch (error) {
      // Handle errors
      console.error("Error deleting tender", error);
      toast.error(error);
      // toast.error("Error deleting tender. Please try again.");
    }
  };

  const handleExpireTender = async (tender) => {
    try {
      const token = localStorage.getItem("token");

      // Make an API call to mark the tender as expired
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/tender/expired/${tender._id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response as needed (e.g., display a success message)
      console.log("Tender marked as expired:", response.data);

      // Dispatch the Redux action to update the state
      dispatch(updateTender({ _id: tender._id, status: "EXPIRED" }));

      fetchTenders(response.data.status);
      // await getItemsToResetAndUpdate(dispatch, fetchTenders, type);
      toast.success("Tender expired successfully");
      navigate(-1);
    } catch (error) {
      // Handle API call errors (e.g., display an error message)
      console.error("Error marking tender as expired:", error);
      // toast.error("Error marking tender as expired");
    }
  };

  const handleMarkAsClosed = async (tender) => {
    console.log("handleMarkAsClosed is being called", tender);
    try {
      const token = localStorage.getItem("token");

      // Make an API call to mark the tender as closed
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/tender/closed/${tender._id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response as needed (e.g., display a success message)
      console.log("Tender marked as closed:", response.data);

      // Dispatch the Redux action to update the state
      // dispatch(updateTender({ _id: tender._id, status: "CLOSED" }));
      toast.success("Tender successfully marked as closed");

      fetchTenders(response.data.status);
      // console.log('sj', response.data, fetchTenders);
      // await getItemsToResetAndUpdate(dispatch, fetchTenders, type);
      navigate(-1);
    } catch (error) {
      // Handle API call errors (e.g., display an error message)
      console.error("Error marking tender as closed:", error);
      toast.error(
        error?.response?.data?.message || "Error marking tender as closed"
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedTender, setSelectedTender] = useState(null);

  const handleConfirmAction = async (action, tender) => {
    switch (action) {
      case "expire":
        await handleExpireTender(tender);
        break;
      case "delete":
        await handleDeleteTender(tender);
        break;
      case "close": // Ensure this matches the action string you set
        await handleMarkAsClosed(tender);
        break;
      // Add more cases as necessary
      default:
        console.error("Unknown action");
    }
    setIsModalOpen(false); // Close modal after action
  };

  // Table Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const pages = Math.ceil((tenderData?.lots?.length || 0) / rowsPerPage);
  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1); // Reset to the first page when rowsPerPage changes
  };

  // Moadals
  const [isModal2Open, setModal2Open] = useState(false);
  // Routes
  const stat = {
    ACTIVE: "active",
    EXPIRED: "expired",
    INQUEUE: "inqueue",
    CLOSED: "closed",
  };
  const totalBidVal = tenderData?.lots?.reduce((p, c) => {
    return getMaxBidValue(c.bids)
      ? p + getMaxBidValue(c.bids) * c.total_carat
      : p;
  }, 0);

  const handleExportClick = async () => {
    setExportLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/export-tender/${tenderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "tender.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false);
    } catch (error) {
      console.error("Error during export operation:", error);
      setExportLoading(false);
    }
  };
  return (
    <>
      {/* View Customer Brid Modal */}
      {isOpen && (
        <Customerbriddetails
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          selectedLot={selectedLot}
          tenderData={tenderData}
          wonBids={wonBids}
          handlechangeSetWonBid={handlechangeSetWonBid}
        />
      )}
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header bg-grayeight">
            <div className=" inline-flex w-full justify-between gap-5">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      <Link
                        className="back_arrow"
                        to={
                          stat[tenderData.status] !== "active" &&
                            stat[tenderData.status] !== "expired"
                            ? `/open-tender/${stat[tenderData.status]}`
                            : "/open-tender/all"
                        }
                      >
                        <ArrowLeft size={24} weight="bold" />
                      </Link>
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold ">
                        {tenderData?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div className="flex items-center justify-between gap-5">
                      <div className="order-2 xl:order-1">
                        {tenderData?.status === "EXPIRED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {tenderData?.status}
                          </Chip>
                        )}
                        {tenderData?.status === "CLOSED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {tenderData?.status}
                          </Chip>
                        )}
                        {tenderData?.status === "ACTIVE" && (
                          <Chip className="chip text-green bg-light-green">
                            {tenderData?.status}
                          </Chip>
                        )}
                        {tenderData?.status === "INQUEUE" && (
                          <Chip className="chip text-orange bg-light-orange">
                            {tenderData?.status}
                          </Chip>
                        )}
                      </div>
                      <div className="order-1 flex gap-4 xl:order-2 z-0">
                        <div>
                          <Button
                            className="savetender_btn"
                            type="submit"
                            isDisabled={exportloading}
                            onClick={handleExportClick}
                          >
                            <div> Export to Excel</div>
                            {exportloading && (
                              <div className="export-loader ms-4"></div>
                            )}
                          </Button>
                        </div>
                        <div>
                          <Dropdown
                            showArrow
                            radius="none"
                            className="border border-grayfive"
                            classNames={{
                              base: "before:bg-white before:border before:border-graythree",
                              content:
                                "p-0 border border-graythree shadow-none",
                            }}
                          >
                            <DropdownTrigger>
                              <Button className="rounded p-3 flex items-center gap-3.5  border border-grayfive bg-grayeleven">
                                <p className="text-sm leading-4.5  font-medium text-mutedtwo">
                                  More Action
                                </p>
                                <CaretDown
                                  size={14}
                                  color="#838383"
                                  weight="bold"
                                />
                              </Button>
                            </DropdownTrigger>
                            <DropdownMenu className="z-10">
                              <DropdownSection className="mb-0 py-2 moreactiondrop">
                                <DropdownItem className="p-0">
                                  <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                    <Link
                                      to={`/open-tender/${tendertype}/edit-tender/${tenderId}`}
                                      className="text-sm leading-4.5.25 text-blackone"
                                    >
                                      Edit Tender
                                    </Link>
                                  </div>
                                </DropdownItem>

                                <DropdownItem className="py-0 my-1">
                                  <div className="border p-0 border-wheat mx-1 cursor-default"></div>
                                </DropdownItem>

                                {tenderData.status !== "EXPIRED" && (
                                  <DropdownItem className="p-0">
                                    <div className="py-1.5 px-4 hover:bg-light-red rounded">
                                      <Link
                                        to=""
                                        className="text-sm leading-4.5.25 text-redone"
                                        onClick={() => {
                                          setSelectedAction("expire");
                                          setSelectedTender(tenderData);
                                          setIsModalOpen(true);
                                        }}
                                      >
                                        Expire Tender
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                )}
                                {tenderData.status !== "CLOSED" && (
                                  <DropdownItem className="p-0">
                                    <div className="py-1.5 px-4 hover:bg-light-blue rounded">
                                      <Link
                                        to=""
                                        className="text-sm leading-4.5.25 text-blue"
                                        //onClick={handleEditTenderClick(tender, {text : 'mark as close' })}
                                        onClick={() => {
                                          setSelectedAction("close");
                                          setSelectedTender(tenderData);
                                          setIsModalOpen(true);
                                        }}
                                      >
                                        Mark as Closed
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                )}

                                <DropdownItem className="p-0 ">
                                  <div className="py-1.5 px-4 hover:bg-light-red rounded">
                                    <Link
                                      to=""
                                      className="text-sm leading-4.5.25 text-darkred"
                                      onClick={() => {
                                        setSelectedAction("delete");
                                        setSelectedTender(tenderData);
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      Delete Tender
                                    </Link>
                                  </div>
                                </DropdownItem>
                              </DropdownSection>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to={
                  stat[tenderData.status] !== "active" &&
                    stat[tenderData.status] !== "expired"
                    ? `/open-tender/${stat[tenderData.status]}`
                    : "/open-tender/all"
                }
                className="cancle_arrow"
              >
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
            {tenderData && (
              <div className="bg-white mt-7.5 border border-mutedeight rounded-2.5">
                <div className="flex items-start tender_details md:py-4 py-6 px-13">
                  <div className="flex items-start md:justify-between tender_info">
                    <div>
                      <span>START DATE</span>
                      <p className="text-darkone ">
                        {formatDate(tenderData.startDate)}
                      </p>
                    </div>
                    <div>
                      <span>END DATE</span>
                      <p className="text-darkone">
                        {formatDate(tenderData.endDate)}
                      </p>
                    </div>
                    <div>
                      <span>REMAINING TIME</span>
                      <p className="text-redthree">
                        {tenderData.remainingTime}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="total_value">
                      <span>TOTAL BID VALUE</span>
                      <p className="text-dark-blue ">
                        $
                        {totalBidVal > 0
                          ? new Intl.NumberFormat("en-IN").format(
                            totalBidVal.toFixed(2)
                          )
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="py-7.5 pb-20 mt-2.5">
            <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
              <p className="mb-2.5">All Lots</p>
            </h6>
            <div ref={topScroll} className="overflow-auto">
              <Table
                radius="none"
                removeWrapper
                className="custom-table border-t-2 border-graytwo"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>LOT NO.</TableColumn>
                  <TableColumn>DESCRIPTION</TableColumn>
                  <TableColumn>SIZE</TableColumn>
                  <TableColumn>NO OF STONE</TableColumn>
                  <TableColumn>TOTAL CARAT</TableColumn>
                  <TableColumn>NO. of BIDS</TableColumn>
                  <TableColumn>MAX BID VALUE ($/CT)</TableColumn>
                  <TableColumn>MAX VALUE</TableColumn>
                  <TableColumn>STATUS</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                </TableHeader>
                <TableBody>
                  {tenderData?.lots
                    ?.sort((a, b) => a.lot_num.localeCompare(b.lot_num))
                    ?.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num))
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((lot, index) => (
                      <TableRow
                        onClick={() => {
                          setSelectedLot(lot);
                          onOpen();
                        }}
                        className="cursor-pointer"
                        key={lot.key}
                      >
                        <TableCell className="">
                          <div>{lot.lot_num}</div>
                        </TableCell>
                        <TableCell>
                          <div>{lot.description}</div>
                        </TableCell>
                        <TableCell>
                          <div>{lot.size === "" ? "-" : lot.size}</div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {lot.no_stone === null ? "-" : lot.no_stone}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {new Intl.NumberFormat("en-IN").format(
                              lot.total_carat
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {lot.bids.length > 0 ? lot.bids.length : "0"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-blueone font-semibold">
                            $
                            {getMaxBidValue(lot.bids) > 0
                              ? new Intl.NumberFormat("en-IN").format(
                                getMaxBidValue(lot.bids)
                              )
                              : "0"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-blueone font-semibold">
                            $
                            {getMaxBidValue(lot.bids) * lot.total_carat > 0
                              ? new Intl.NumberFormat("en-IN").format(
                                (
                                  getMaxBidValue(lot.bids) * lot.total_carat
                                ).toFixed(2)
                              )
                              : "0"}
                          </div>
                        </TableCell>
                        <TableCell>
                          {lot.status === "done" && (
                            <div className="text-green font-semibold uppercase">
                              {lot.status}
                            </div>
                          )}
                          {lot.status === "withdraw" && (
                            <div className="text-graynine font-semibold uppercase">
                              {lot.status}
                            </div>
                          )}
                          {lot.status === "pending" && (
                            <div className="text-orange font-semibold uppercase">
                              {lot.status}
                            </div>
                          )}
                          {lot.status === "rejected" && (
                            <div className="text-tometo font-semibold uppercase">
                              {lot.status}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="flex justify-end">
                            <Info size={20} color="#004f9c" weight="bold" />
                          </div>
                        </TableCell>
                        <TableCell className="z-0">
                          {/* Active Satus DropDown */}
                          <div className="flex items-center w-4.5 z-0 h-full justify-end">
                            <Dropdown
                              placement="bottom-end"
                              radius="sm"
                              className="drop-box "
                              classNames={{ content: "p-0 shadow-drop " }}
                            >
                              <DropdownTrigger>
                                <Image
                                  alt="Edit Image Icon"
                                  src="/box-img/EditerToggle.svg"
                                  className="rounded-none cursor-pointer"
                                />
                              </DropdownTrigger>
                              <DropdownMenu className="z-10">
                                <DropdownSection className="mb-0 py-2">
                                  <DropdownItem className="p-0 rounded-none">
                                    <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                      <Link
                                        to=""
                                        onClick={() => {
                                          setSelectedLot(lot);
                                          onOpen();
                                        }}
                                        className="text-sm leading-4.5.25 text-blackone"
                                      >
                                        View Customer Bids
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem className="py-0 mt-1">
                                    <div className="border p-0 border-wheat mx-1 cursor-default"></div>
                                  </DropdownItem>
                                  <DropdownItem className="p-0 rounded-none mt-1 ">
                                    <div className=" py-1.5 px-4 hover:bg-light-red rounded">
                                      <Link
                                        to=""
                                        className="text-sm leading-4.5.25 text-redtwo"
                                        onClick={() => {
                                          editTenderLots(lot?._id);
                                        }}
                                      >
                                        Mark as Withdraw
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                </DropdownSection>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
            {/* Pagination */}
            { tenderData?.lots && tenderData?.lots.length ? <div className="lg:px-7.5 px-5 paginations">
              <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
                <div className="flex items-center gap-2">
                  <p className="text-darkone text-sm leading-4.5.25 font-medium">
                    Page size
                  </p>
                  <select
                    className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                    onChange={handlePageSizeChange}
                    value={rowsPerPage}
                  >
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                </div>
                <Pagination
                  // isCompact
                  showControls
                  total={pages || 1}
                  page={page}
                  // color="warning"
                  classNames={{
                    wrapper: "rounded ",
                    item: "rounded ",
                    cursor:
                      "bg-dark-blue  text-white rounded",
                  }}

                  initialPage={1}
                  onChange={(newPage) => {
                    setPage(newPage);
                    topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                />
              </div>
            </div> : null}
            <ConfirmationTenderModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              action={selectedAction}
              currentTender={tenderData}
              confirmStatusChange={() =>
                handleConfirmAction(selectedAction, selectedTender)
              }
            />
          </div>
        </>
      )}
    </>
  );
}
