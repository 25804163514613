// import axios from 'axios';

import {
  CREATE_TENDER,
  GET_ACTIVE_TENDER,
  EDIT_TENDER,
  SET_TENDERS,
  GET_TENDER_BY_ID,
  GET_ALL_USER_BIDS,
  EDIT_TENDER_LOTS,
  MARK_BID_AS_WON,
  SAVE_TENDER_SUCCESS,
  SAVE_TENDER_FAILURE
} from './actionTypes';

// Import the service functions
import * as TenderService from "../services/tenderService";

export const createTender = (tenderData) => async (dispatch, getState) => {
  try {
    const { authToken } = getState().auth;
    console.log('Auth Token:', authToken); // Log the token
    const createdTender = await TenderService.createTenderService(tenderData, authToken);
    dispatch({
      type: CREATE_TENDER,
      payload: createdTender,
    });
  } catch (error) {
    console.error('Error creating tender:', error);
  }
};

export const getActiveTender = () => async (dispatch) => {
  try {
    const activeTenders = await TenderService.getActiveTenderService();
    dispatch({
      type: GET_ACTIVE_TENDER,
      payload: activeTenders,
    });
  } catch (error) {
    console.error('Error getting active tender:', error);
  }
};

export const editTender = (tenderId, updatedData) => async (dispatch) => {
  try {
    const updatedTender = await TenderService.editTenderService(tenderId, updatedData);
    dispatch({
      type: EDIT_TENDER,
      payload: updatedTender,
    });
  } catch (error) {
    console.error('Error editing tender:', error);
  }
};

export const updateTender = (updateTender) => ({
  type: 'UPDATE_TENDER',
  payload: updateTender,
});

export const setTenders = (tenders) => ({
  type: SET_TENDERS,
  payload: tenders,
});

export const getTenderById = (tenderId) => async (dispatch) => {
  try {
    const tender = await TenderService.getTenderByIdService(tenderId);
    dispatch({
      type: GET_TENDER_BY_ID,
      payload: tender,
    });
  } catch (error) {
    console.error('Error getting tender by ID:', error);
  }
};

export const getAllUserBids = (lotId, page = 1, limit = 10) => async (dispatch) => {
  try {
    const userBids = await TenderService.getAllUserBidsService(lotId, page, limit);
    dispatch({
      type: GET_ALL_USER_BIDS,
      payload: userBids,
    });
  } catch (error) {
    console.error('Error getting all user bids:', error);
  }
};

export const editTenderLots = (lotId, updatedFields) => async (dispatch) => {
  try {
    const updatedLots = await TenderService.editTenderLotsService(lotId, updatedFields);
    dispatch({
      type: EDIT_TENDER_LOTS,
      payload: updatedLots,
    });
  } catch (error) {
    console.error('Error editing tender lots:', error);
  }
};

export const markBidAsWon = (bidId) => async (dispatch) => {
  try {
    await TenderService.markBidAsWonService(bidId);
    dispatch({
      type: MARK_BID_AS_WON,
      payload: bidId,
    });
  } catch (error) {
    console.error('Error marking bid as won:', error);
  }
};

export const saveTenderSuccess = (data) => ({
  type: SAVE_TENDER_SUCCESS,
  payload: data,
});

export const saveTenderFailure = (error) => ({
  type: SAVE_TENDER_FAILURE,
  payload: error,
});

