import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image,
  Input,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  useDisclosure,
  Pagination
} from "@nextui-org/react";
import {
  ArrowLeft,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CaretRight,
  X,
} from "@phosphor-icons/react";
import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import {
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import Changepassword from "../components/Changepassword";
import Confirmationmodal from "../components/Confirmationmodal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bidsdetails from "./Bidsdetails";
import "./Viewactivecustomer.scss";
import ProfilePhotoModal from "../components/ProfilePhotoModal";
import { CustomerUpdateContext } from "../components/Sidebar";

export default function ViewActiveCustomer() {
  // View Brid Detals Modal
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedTender, setSelectedTender] = useState(Number);
  const [selectedAction, setSelectedAction] = useState("");
  const { isUpdated, setIsUpdated } = useContext(CustomerUpdateContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let query = params.get("status");

  const topScroll = useRef(null)

  // Slected Tab
  const [selected, setSelected] = React.useState(query);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { customerId, status } = useParams();
  // Table Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const pages = Math.ceil(customers.length / rowsPerPage);
  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return customers.slice(start, end);
  }, [page, rowsPerPage, customers]);

  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleViewBidsClick = (customer) => {
    onOpen();

    setSelectedTender(customer?.lots);
  };


  const [showImg, setShowImg] = useState(false);

  const handleShowImgClick = () => {
    setShowImg(!showImg);

  }

  const fetchCustomers = async (status) => {
    setLoading(true);
    try {
      // Add a check to ensure selectedCustomer is not null
      const token = localStorage.getItem('token');
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomerById/${customerId}?status=${status}`;
      console.log("apiUrl", apiUrl);
      const response = await fetch(`${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
        },
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Error fetching customers:", data);
        // Handle the error, e.g., show an error message to the user
        return;
      }

      // The request was successful (status code 2xx)
      setCustomers(data?.activeTenderLots);

      setSelectedCustomer(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
    finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchCustomers(selected);
  }, [selected]);

  const updateCustomerStatus = async (_id, status) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/updateCustomerStatus`;
      const token = localStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ _id, status }),
      });

      const data = await response.json();
      setIsUpdated(true);
      console.log("isUpdated : ", isUpdated);
      fetchCustomers();

      if (response.ok) {
        console.log(data.message);

        // Display toast based on the action
        switch (status) {
          case "approved":
            toast.success("Customer Approved Successfully");
            break;
          case "rejected":
            toast.success("Customer Rejected Successfully");
            break;
          case "deleted":
            toast.success("Customer Deleted Successfully");
            break;
          default:
            break;
        }
      } else {
        console.error(data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error updating customer status");
    }
  };

  const [currentCustomer, setCurrentCustomer] = useState({
    _id: "",
    status: "", // assuming the default status could be 'pending', 'approved', or 'rejected'
  });

  const handleToggle = (customer, action) => {
    setSelectedAction(action);
    setCurrentCustomer(customer);
    setModal1Open(true);
  };

  const confirmStatusChange = async (newStatus) => {
    // Placeholder for the function to update customer status
    // Update customer status here, then close modal and update customer's status locally
    await updateCustomerStatus(currentCustomer._id, newStatus); // Implement actual API call here
    setCurrentCustomer({ ...currentCustomer, status: newStatus });
    setModal1Open(false);

    navigate(-1);
  };

  const handleTabChange = (key) => {
    // Update the selected state
    setSelected(key);

    // Update the query string in the URL
    navigate(`?status=${key}`, { replace: true });

    // Call the fetchCustomers function with the new status
    fetchCustomers(key);
  };


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCustomers(customers)
    }
    else {
      // Filter the tenders based on the search term
      const filtered = customers.filter(customer =>
        customer?.tenderId?.tenderId.toLowerCase().includes(searchTerm.toLowerCase())
        //customer.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
    }
  }, [customers, searchTerm]);

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    }).replace('am', 'AM').replace('pm', 'PM');
    //const  = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() }`;

    return formattedDate;
  };
  const [currentCustomerId, setCurrentCustomerId] = useState(null)
  // Moadals
  const [isModal1Open, setModal1Open] = useState(false);
  const [isModal2Open, setModal2Open] = useState(false);

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };


  return (
    <>
      {isOpen && (
        <Bidsdetails
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          selectedTender={selectedTender}
        />
      )}
      {
        <ProfilePhotoModal isOpen={showImg} onClose={() => setShowImg(false)} customer={selectedCustomer?.customer} />
      }
      {/* {isOpen && <Bidsdetails selectedId={selectedId} isOpen={isOpen} onOpenChange={onOpenChange} />} */}

      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header bg-grayeight">
            <div className=" inline-flex w-full justify-between gap-5  ">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      <Link to={`/${selectedCustomer?.customer?.status}`} className="back_arrow">
                        <ArrowLeft size={24} weight="bold" />
                      </Link>

                      <div className="z-0 w-8 rounded-0.5 user_img overflow-hidden cursor-pointer" onClick={handleShowImgClick}>
                        {selectedCustomer?.customer?.profile_photo ? <Image
                          alt="Customer Images"
                          src={selectedCustomer?.customer?.profile_photo}
                          className=" rounded object-cover"
                        /> :
                          <div className='flex items-center justify-center rounded-full bg-wheat text-black font-bold text-xl' >
                            {selectedCustomer?.customer?.customerName?.charAt(0)?.toUpperCase()}
                          </div>
                        }
                      </div>
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold ">
                        {selectedCustomer?.customer?.customerName} {selectedCustomer?.customer?.fatherName} {selectedCustomer?.customer?.surname}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div className="flex items-center justify-between gap-5">
                      <div className="order-2 xl:order-1">
                        {selectedCustomer?.customer?.status === 'pending' && <Chip className="chip text-orange bg-light-orange">Pending</Chip>}
                        {selectedCustomer?.customer?.status === 'approved' && <Chip className="chip text-green bg-light-green">Active</Chip>}
                        {selectedCustomer?.customer?.status === 'rejected' && <Chip className="chip text-redtwo bg-light-red">Inactive</Chip>}

                      </div>
                      <div className="order-1 xl:order-2 z-0">
                        <Dropdown
                          showArrow
                          radius="none"
                          className="border border-grayfive"
                          classNames={{
                            base: "before:bg-white before:border before:border-graythree",
                            content: "p-0 border border-graythree shadow-none",
                          }}
                        >

                          <DropdownTrigger>
                            <Button className="rounded p-3 flex items-center gap-3.5 border border-grayfive bg-grayeleven">
                              <p className="text-sm leading-4.5  font-medium text-mutedtwo" >
                                More Action
                              </p>
                              <CaretDown size={14} color="#838383" weight="bold" />
                            </Button>
                          </DropdownTrigger>

                          <DropdownMenu className="z-10">
                            <DropdownSection className="mb-0 py-2 moreactiondrop">
                              <DropdownItem className="p-0">
                                <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                  <Link
                                    to={`/${selectedCustomer?.customer?.status}/editcustomer/${customerId}`}
                                    className="text-sm leading-4.5.25 text-blackone"
                                  >
                                    Edit Customer
                                  </Link>
                                </div>
                              </DropdownItem>

                              <DropdownItem className="p-0">
                                <div className="py-1.5 px-4 hover:bg-grayeleven w-full rounded">
                                  <Link
                                    to=""
                                    className="text-sm leading-4.5.25 text-blackone"
                                    onClick={() => {
                                      setCurrentCustomerId(customerId)
                                      setModal2Open(true);
                                    }}
                                  >
                                    Change Password
                                  </Link>
                                </div>
                              </DropdownItem>

                              <DropdownItem className="py-0 my-1">
                                <div className="border p-0 border-wheat mx-1 cursor-default" ></div>
                              </DropdownItem>


                              {status !== 'approved' && <DropdownItem className="p-0 ">
                                <div className="text-sm leading-4.5.25 text-blue py-1.5 px-4 hover:bg-light-blue rounded"
                                  onClick={() => {
                                    handleToggle(selectedCustomer?.customer, "approve")
                                  }}>
                                  Approve Customer
                                </div>
                              </DropdownItem>}

                              {status !== 'rejected' && <DropdownItem className="p-0">
                                <div className="text-sm leading-4.5.25 text-redone py-1.5 px-4 hover:bg-light-red rounded" onClick={() => {
                                  handleToggle(selectedCustomer?.customer, "rejected")
                                }}>
                                  Reject Customer
                                </div>
                              </DropdownItem>}

                              {status !== 'deleted' && <DropdownItem className="p-0">
                                <div className="py-1.5 px-4 hover:bg-light-red w-full rounded"
                                  onClick={() => {
                                    handleToggle(selectedCustomer?.customer, "delete")
                                  }}
                                >
                                  Delete Customer
                                </div>
                              </DropdownItem>}
                            </DropdownSection>
                          </DropdownMenu>

                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/${selectedCustomer?.customer?.status}`} className="cancle_arrow">
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
            <div className="bg-white mt-7.5 border border-mutedeight rounded-2.5">
              <div className="customer_details md:py-4 py-6 px-13">
                <div>
                  <span>ID</span>
                  <p>{selectedCustomer?.customer?.customerId}</p>
                </div>
                <div>
                  <span>MOBILE</span>
                  <p>{selectedCustomer?.customer?.mobileNumber}</p>
                </div>
                <div>
                  <span>VILLAGE</span>
                  <p>{selectedCustomer?.customer?.villageName}</p>
                </div>
                <div>
                  <span>COMPANY</span>
                  <p>{selectedCustomer?.customer?.companyName}</p>
                </div>
                <div>
                  <span>DALAL NAME</span>
                  <p>{selectedCustomer?.customer?.agentName}</p>
                </div>
                <div>
                  <span>DALAL NUMBER</span>
                  <p>{selectedCustomer?.customer?.agentMobile}</p>
                </div>
                <div>
                  <span>CREATE ON</span>
                  <p>
                    {selectedCustomer?.customer?.createdAt &&
                      new Date(selectedCustomer.customer.createdAt).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true
                      }).replace('am', 'AM').replace('pm', 'PM')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex gap-3 xl:flex-row flex-col justify-between">

              <Tabs
                aria-label="Options"
                selectedKey={selected}
                onSelectionChange={handleTabChange}
                className="inline-flex sm:justify-start justify-center sm:max-w-full max-w-[200px] sm:m-0 mx-auto items-end changebg"
                classNames={{
                  tabList:
                    "relative gap-4.25 xs:flex-nowrap flex-wrap rounded-none p-0 bg-transparent",
                  cursor:
                    "shadow-none border-b-2 border-dark-blue inline-block rounded-none bg-transparent",
                  tab: "rounded-none text-darkmuted px-0 bg-transparent h-9.5 ",
                  tabContent:
                    "group-data-[selected=true]:text-dark-blue text-darkmuted mb-3 font-semibold text-4.25 leading-6.25",
                }}
              >
                <Tab
                  key="open_bids"
                  title={<div className="px-7.5">Open Bids</div>}
                />
                <Tab key="won_bids" title={<div className="px-7.5">Won Bids</div>} />
                <Tab
                  key="bids_history"
                  title={<div className="px-7.5">Bids History</div>}
                />
              </Tabs>

              <div className="px-7.5 mb-4 flex-grow sm:max-w-[397px] max-w-full">
                <Input
                  radius="none"
                  classNames={{
                    input: [
                      "placeholder:text-sm placeholder:text-mutedtwo text-mutedtwo leading-4 p-0 outline-none h-full  flex justify-end",
                    ],
                    innerWrapper: "bg-transparent",
                    inputWrapper: [
                      "bg-transparent shadow-none  border border-grayfive !cursor-text px-5 h-11 py-0 ",
                    ],
                  }}
                  placeholder="Search"
                  value={searchTerm}
                  onChange={updateSearchTerm}
                />
              </div>
            </div>

            <Tabs
              aria-label="Options"
              selectedKey={selected}
              onSelectionChange={setSelected}
              className="details-tabs"
              classNames={{ tabList: "hidden", }}
            >
              <Tab key="open_bids" className="p-0 ">
                <div ref={topScroll} className="overflow-auto pb-20">
                  <Table
                    radius="none"
                    removeWrapper
                    className="custom-table border-t-2 border-graytwo h-full overflow-auto"
                    aria-label="Example static collection table "
                  >
                    <TableHeader>
                      <TableColumn>TENDER ID</TableColumn>
                      <TableColumn>START DATE</TableColumn>
                      <TableColumn>END DATE</TableColumn>
                      <TableColumn>TOTAL LOT</TableColumn>
                      <TableColumn>YOUR BID</TableColumn>
                      <TableColumn>STATUS</TableColumn>
                      <TableColumn></TableColumn>
                    </TableHeader>

                    <TableBody>
                      {filteredCustomers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((customer) => {
                        return (
                          <TableRow
                            className="cursor-pointer"
                            onClick={() => handleViewBidsClick(customer)}
                            key={customer?.tenderId}
                          >
                            <TableCell>{customer?.tenderId?.tenderId}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.startDate)}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.endDate)}</TableCell>
                            <TableCell>
                              {customer?.tenderId?.lots?.length}
                            </TableCell>
                            <TableCell>{customer?.lots?.length}</TableCell>

                            <TableCell>
                              {customer?.tenderId?.status === 'pending' && <div className="text-green font-semibold uppercase">{customer?.tenderId?.status}</div>}
                              {customer?.tenderId?.status === 'active' && <div className="text-green font-semibold uppercase">{customer?.tenderId?.status}</div>}
                              {customer?.tenderId?.status === 'expired' && <div className="text-red font-semibold uppercase">{customer?.tenderId?.status}</div>}
                              <div className="text-green font-semibold uppercase">{customer?.tenderId?.status}</div>
                              {/* <div className="text-blue font-semibold hidden">
                            {customer?.tenderId?.status_close}
                          </div>
                          <div className="text-redtwo font-semibold hidden">
                            {customer?.tenderId?.status_expired}
                          </div> */}
                            </TableCell>
                            <TableCell className="z-0">
                              <div className="flex items-center z-0 h-full justify-end">
                                <div
                                  className="bg-light-blue w-22 rounded-2.5 px-3 py-1 cursor-pointer"
                                  onClick={() => handleViewBidsClick(customer._id)}
                                >
                                  {/* onClick={() => toggleExpandRow(lot.key)} */}
                                  <div className="flex items-center gap-4 ">
                                    <p className="text-sm font-semibold text-dark-blue">
                                      View
                                    </p>
                                    <CaretDown
                                      size={14}
                                      color="#004f9c"
                                      weight="fill"
                                    />
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Tab>
              <Tab key="won_bids" className="p-0">
                <div ref={topScroll} className="overflow-auto pb-20">
                  <Table
                    radius="none"
                    removeWrapper
                    className="custom-table border-t-2 border-graytwo h-full overflow-auto"
                    aria-label="Example static collection table "
                  >
                    <TableHeader>
                      <TableColumn>TENDER ID</TableColumn>
                      <TableColumn>START DATE</TableColumn>
                      <TableColumn>END DATE</TableColumn>
                      <TableColumn>TOTAL LOT</TableColumn>
                      <TableColumn>YOUR BID</TableColumn>
                      <TableColumn>STATUS</TableColumn>
                      <TableColumn></TableColumn>
                    </TableHeader>

                    <TableBody>
                      {filteredCustomers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((customer) => {
                        return (
                          <TableRow
                            className="cursor-pointer"
                            onClick={() => handleViewBidsClick(customer)}
                            key={customer._id}
                          >

                            <TableCell>{customer?.tenderId?.tenderId}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.startDate)}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.endDate)}</TableCell>
                            <TableCell>
                              {customer?.tenderId?.lots?.length}
                            </TableCell>
                            <TableCell>{customer?.lots?.length}</TableCell>

                            <TableCell>
                              <div className="text-green font-semibold uppercase">
                                {customer?.tenderId?.status}
                              </div>
                              {/* <div className="text-blue font-semibold hidden">
                            {customer?.tenderId?.status_close}
                          </div>
                          <div className="text-redtwo font-semibold hidden">
                            {customer?.tenderId?.status_expired}
                          </div> */}
                            </TableCell>
                            <TableCell className="z-0">
                              <div className="flex items-center z-0 h-full justify-end">
                                <div
                                  className="bg-light-blue w-22 rounded-2.5 px-3 py-1 cursor-pointer"
                                  onClick={() => handleViewBidsClick(customer._id)}
                                >
                                  {/* onClick={() => toggleExpandRow(lot.key)} */}
                                  <div className="flex items-center gap-4 ">
                                    <p className="text-sm font-semibold text-dark-blue">
                                      View
                                    </p>
                                    <CaretDown
                                      size={14}
                                      color="#004f9c"
                                      weight="fill"
                                    />
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Tab>
              <Tab key="bids_history" className="p-0">
                <div ref={topScroll} className="overflow-auto pb-20">
                  <Table
                    radius="none"
                    removeWrapper
                    className="custom-table border-t-2 border-graytwo h-full overflow-auto"
                    aria-label="Example static collection table "
                  >
                    <TableHeader>
                      <TableColumn>TENDER ID</TableColumn>
                      <TableColumn>START DATE</TableColumn>
                      <TableColumn>END DATE</TableColumn>
                      <TableColumn>TOTAL LOT</TableColumn>
                      <TableColumn>YOUR BID</TableColumn>
                      <TableColumn>STATUS</TableColumn>
                      <TableColumn></TableColumn>
                    </TableHeader>

                    <TableBody>
                      {filteredCustomers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((customer) => {
                        return (
                          <TableRow
                            className="cursor-pointer"
                            onClick={() => handleViewBidsClick(customer)}
                            key={customer._id}
                          >
                            <TableCell>{customer?.tenderId?.tenderId}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.startDate)}</TableCell>
                            <TableCell>{formatDate(customer?.tenderId?.endDate)}</TableCell>
                            <TableCell>
                              {customer?.tenderId?.lots?.length}
                            </TableCell>
                            <TableCell>{customer?.lots?.length}</TableCell>
                            <TableCell>
                              <div className="text-green font-semibold uppercase">
                                {customer?.tenderId?.status}
                              </div>
                              {/* <div className="text-blue font-semibold hidden">
                            {customer?.tenderId?.status_close}
                          </div>
                          <div className="text-redtwo font-semibold hidden">
                            {customer?.tenderId?.status_expired}
                          </div> */}
                            </TableCell>
                            <TableCell className="z-0">
                              <div className="flex items-center z-0 h-full justify-end">
                                <div
                                  className="bg-light-blue w-22 rounded-2.5 px-3 py-1 cursor-pointer"
                                  onClick={() => handleViewBidsClick(customer._id)}
                                >
                                  {/* onClick={() => toggleExpandRow(lot.key)} */}
                                  <div className="flex items-center gap-4 ">
                                    <p className="text-sm font-semibold text-dark-blue">
                                      View
                                    </p>
                                    <CaretDown
                                      size={14}
                                      color="#004f9c"
                                      weight="fill"
                                    />
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Tab>
            </Tabs>

            {/* Pagination */}
            {
              filteredCustomers && filteredCustomers.length ? <div className="lg:px-7.5 px-5 paginations">
                <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
                  <div className="flex items-center gap-2">
                    <p className="text-darkone text-sm leading-4.5.25 font-medium">
                      Page size
                    </p>
                    <select
                      className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                      onChange={handlePageSizeChange}
                      value={rowsPerPage}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <Pagination
                    // isCompact
                    showControls
                    total={pages || 1}
                    page={page}
                    // color="warning"
                    classNames={{
                      wrapper: "rounded ",
                      item: "rounded ",
                      cursor:
                        "bg-dark-blue  text-white rounded",
                    }}

                    initialPage={1}
                    onChange={(newPage) => {
                      setPage(newPage);
                      topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                </div>
              </div> : null
            }

          </div>
        </>
      )}

      {/* Modlas */}
      <Confirmationmodal
        isOpen={isModal1Open}
        onClose={() => setModal1Open(false)}
        selectedAction={selectedAction}
        currentCustomer={currentCustomer}
        confirmStatusChange={confirmStatusChange}
      />{" "}
      <Changepassword isOpen={isModal2Open} onClose={() => setModal2Open(false)} currentCustomerId={currentCustomerId} />  {/* Change Password Moadl */}
    </>
  );
}
