import React, { useState, useEffect, useRef } from "react";
import { Image, Input, Button } from "@nextui-org/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { X, ArrowLeft, Eye, EyeSlash, Trash, Pencil } from "@phosphor-icons/react";
import "./Newcustomer.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Newcustomer.scss";

import ReactCrop, { centerCrop, makeAspectCrop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { width } from "@mui/system";

export default function EditCustomer() {
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { customerId, status } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleVisibility = () => setIsVisible(!isVisible);

  //crop

  const [isCropping, setIsCropping] = useState(false);
  const [x, setX] = useState("");

  const [crop, setCrop] = useState({

  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(imagePreview);
  const imageRef = useRef();
  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }
  const onImageLoaded = (e) => {

    const { width, height } = e.target

    setCrop(centerAspectCrop(width, height, 1))
    imageRef.current = e.target;

  };

  const onCropChange = (_, crop) => {
    // You can use either the pixel crop or the percentCrop
    setIsCropping(true);
    setCrop(crop);
    // console.log("On Crop Change : ", crop);
  };

  const onCropComplete = async (crop) => {
    console.log("On Crop Complete : ", imageRef);
    setIsCropping(true);
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    console.log("bchujsabvcjhsd : ", crop);
    const canvas = document.createElement("canvas");

    const ctx = canvas.getContext("2d");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    console.log("scaleX : ", scaleX);
    console.log("scaleY : ", scaleY);

    if (crop.unit !== "px") {
      console.log("Crop Unit : ", crop.unit);
      const pixelCrop = {
        x: (crop.x / 100) * image.width,
        y: (crop.y / 100) * image.height,
        width: (crop.width / 100) * image.width,
        height: (crop.height / 100) * image.height,
      };

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      console.log("pixelCrop.width : ", pixelCrop.width);
      console.log("pixelCrop.height : ", pixelCrop.height);
    }

    console.log("Crop.width : ", crop.width);
    console.log("Crop.height : ", crop.height);
    console.log("image.width : ", image.width);
    console.log("image.width : ", image.width);

    //

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    let fileUrl = "";
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        //console.log("Blob Size : ", blob)
        blob.name = fileName;
        URL.revokeObjectURL(fileUrl);
        fileUrl = URL.createObjectURL(blob);
        //console.log("File Url : ", fileUrl);
        const imageElement = document.createElement("img");
        imageElement.src = fileUrl;
        //console.log("File Url : ", fileUrl);
        const blobUrl = URL.createObjectURL(blob);

        //console.log("Blob Url : ", blobUrl);
        // setX(blob);
        resolve(fileUrl);
      }, "image/png");
    });
  };

  const editCustomer = async (customerData, token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      // Make API request to update customer details
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/editCustomer/${customerId}`,
        customerData,
        { headers }
      );
      toast.success("Customer updated successfully");
    } catch (error) {
      console.error("Error updating customer:", error);
      toast.error("Error updating customer");
    }
  };

  const formik = useFormik({
    initialValues: {
      customerName: "",
      companyName: "",
      fatherName: "",
      surname: "",
      mobileNumber: "",
      villageName: "",
      agentName: "",
      agentMobile: "",
      password: "",
      profile_photo: "",
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required("Customer name is required"),
      fatherName: Yup.string().required("Father name is required"),
      surname: Yup.string().required("Surname is required"),
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, "Must be a 10-digit valid number"),
      villageName: Yup.string().required("Village name is required"),
      // agentName: Yup.string().required("Agent name is required"),
      agentMobile: Yup.string().matches(
        /^\d{10}$/,
        "Must be a 10-digit valid number"
      ),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitLoading(true);
        // Create FormData for sending files
        const formData = new FormData();

        // if (croppedImageUrl) {
        //   const response = await fetch(croppedImageUrl);
        //   const blob = await response.blob();
        //   formData.append("profile_photo", blob);
        // } else if (values.profile_photo !== "") {
        //   formData.append("profile_photo", values.profile_photo);
        // } else {
        //   // Fallback to the original file if for some reason the cropped image is not available
        //   values.profile_photo
        //     ? formData.append("profile_photo", values.profile_photo)
        //     : formData.append(
        //       "profile_photo",
        //       document.querySelector("input[type=file]").files[0]
        //     );
        // }

        if (croppedImageUrl) {
          const response = await fetch(croppedImageUrl);
          const blob = await response.blob();
          formData.append("profile_photo", blob);
        } else if (values.profile_photo !== "") {
          formData.append("profile_photo", values.profile_photo);
        } else {
          formData.append("profile_photo", "");
        }

        // Append other customer data
        formData.append("customerName", values.customerName);
        formData.append("companyName", values.companyName || '');
        formData.append("fatherName", values.fatherName);
        formData.append("surname", values.surname);
        formData.append("mobileNumber", values.mobileNumber);
        formData.append("villageName", values.villageName);
        formData.append("agentName", values.agentName);
        formData.append("agentMobile", values.agentMobile || '');
        formData.append("password", values.password);

        console.log("formData: ", formData);

        // Make the API call
        await editCustomer(formData, token);
        setCroppedImageUrl("");
        setSubmitLoading(false);

        navigate(`/${status}`);
      } catch (error) {
        // Handle errors
        console.error(error);
        setSubmitLoading(false);
      }
    },
  });

  useEffect(() => {
    // Fetch customer data for editing
    const fetchCustomerData = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/getCustomerById/${customerId}`,
          { headers }
        );
        console.log("Fetched Customer Data:", response.data.customer);

        const {
          customerName,
          companyName,
          fatherName,
          surname,
          mobileNumber,
          villageName,
          agentName,
          agentMobile,
          password,
          profile_photo,
        } = response.data.customer; // Assuming your API returns customer data
        console.log("Fetched Customer Data:", response.data.customer);

        formik.setValues({
          customerName: customerName,
          companyName: companyName,
          fatherName: fatherName,
          surname: surname,
          mobileNumber: mobileNumber,
          agentMobile: agentMobile,
          villageName: villageName,
          agentName: agentName,
          password: password,
          profile_photo: profile_photo,
        });

        if (profile_photo) {
          // Set the image preview URL
          setImagePreview(profile_photo);
        }

        console.log("formik.values after setting:", formik.values);
        // formik.setValues(customerData);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomerData();
  }, []);

  const removeProfile = () => {
    setFileName("");
    setFileError("");
    setImagePreview("");
    setCroppedImageUrl("");
    formik.setFieldValue("profile_photo", ""); // Correctly sets to an empty string
};

  const changeProfile = () => {
    // Trigger the input file click using querySelector
    const fileInput = document.querySelector(
      "input[type=file][name=profile_photo]"
    );
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = (e) => {
    const fileInput = document.querySelector("input[type=file]");
    // const fileInput = e.target.files[0];
    console.log("Selected file: ", fileInput);
    console.log("bcbchb : ", fileInput);
    console.log("hahahah : ", e);

    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      if (file) {
        // Check if the file size is less than or equal to 5MB
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
          setFileError("File size exceeds 5MB. Please choose a smaller file.");
          fileInput.value = ""; // Clear the input
          setFileName("");
          setImagePreview("");
        } else {
          // Check if the file type is allowed
          const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
          if (!allowedFileTypes.includes(file.type)) {
            setFileError("Only PNG, JPG, and JPEG files are allowed.");
            fileInput.value = ""; // Clear the input
            setFileName("");
            setImagePreview("");
          } else {
            setFileError(""); // Clear previous error message
            setFileName(file.name);

            // Read the file content as a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
              setIsCropping(true);
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
          }
        }
      } else {
        setFileError("Invalid file selected. Please choose a valid file.");
        setFileName("");
        setImagePreview("");
      }
    } else {
      setFileError(""); // Clear previous error message
      setFileName("");
      setImagePreview("");
    }
    e.target.value = null
  };

  const applyCrop = async () => {
    // Do something with the cropped image, such as saving it or displaying it
    // For example:
    const croppedImageUrl = await getCroppedImg(
      imageRef.current,
      crop,
      "cropped-image.jpeg"
    );
    console.log("Ceropped Image Url :", croppedImageUrl);
    //setImagePreview(croppedImageUrl);
    setIsCropping(false);
  };

  const cancelCrop = () => {
    // Reset the crop state or perform any other necessary actions
    setCrop({ aspect: 1 });
    setCroppedImageUrl("");
    setImagePreview("");
    setIsCropping(false);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {isCropping  && (
            <div className="backdrops fixed top-0 start-0 end-0 bottom-0 z-[22] flex items-center justify-center">
              <div className="bg-white rounded-md sm:max-w-[500px] max-w-full sm:mx-6 mx-4 border border-mutedseven">
                <ReactCrop
                  src={imagePreview}
                  crop={crop}
                  ruleOfThirds
                  //onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  className="rounded-md  sm:p-4 sm:pb-3 p-1 pb-0 overflow-hidden"
                >
                  <img src={imagePreview} crossOrigin="anonymous" onLoad={onImageLoaded} />
                </ReactCrop>
                <div className="flex items-center justify-end px-4 gap-3 sm:py-3 py-2 border-t border-mutedseven">
                  <button className="bg-light-blue rounded text-blueone text-sm font-semibold px-3 py-1.5" onClick={applyCrop}>Save</button>
                  <button className="bg-light-red text-redone rounded text-sm font-semibold  px-3 py-1.5" onClick={cancelCrop}>Cancel</button>
                </div>
              </div>
            </div>
          )}
          {/* {isCropping && (
            <div>
              <ReactCrop
                src={imagePreview}
                crop={crop}
                // ruleOfThirds
                onChange={onCropChange}
                //onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                
                className="rounded-md  sm:p-4 sm:pb-3 p-1 pb-0 overflow-hidden"
              >
                <img src={imagePreview} onLoad={onImageLoaded} />
              </ReactCrop>
            </div>
            // <div className="backdrops fixed top-0 start-0 end-0 bottom-0 z-[22] flex items-center justify-center" onClick={cancelCrop}>
            //   <div className="bg-white rounded-md sm:max-w-[500px] max-w-full sm:mx-6 mx-4 border border-mutedseven">

            //     <div className="flex items-center justify-end px-4 gap-3 sm:py-3 py-2 border-t border-mutedseven">
            //       <button className="bg-light-blue rounded text-blueone text-sm font-semibold px-3 py-1.5" onClick={applyCrop}>Save</button>
            //       <button className="bg-light-red text-redone rounded text-sm font-semibold  px-3 py-1.5" onClick={cancelCrop}>Cancel</button>
            //     </div>
            //   </div>
            // </div>
          )} */}
          <div className="body-conetnt-header bg-grayseven border-b border-mutedfour">
            <div className="flex items-center justify-between gap-5">
              <div className="flex items-center gap-7">
                <Link to={`/${status}`} className="back_arrow">
                  <ArrowLeft size={24} weight="bold" />
                </Link>
                <h5 className="text-xl leading-7 text-blackone font-semibold">
                  Edit Customer
                </h5>
              </div>
              <Link to={`/${status}`} className="cancle_arrow">
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
          </div>
          <div className="mb-24">
            <div className="lg:px-7.5 px-5 pt-7.5">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-5.5 new-customer-content"
              >
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Customer Name*</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.customerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="customerName"
                  />
                  {formik.touched.customerName && formik.errors.customerName ? (
                    <div className="error-message">
                      {formik.errors.customerName}
                    </div>
                  ) : null}
                </div>

                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Father Name*</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.fatherName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="fatherName"
                  />
                  {formik.touched.fatherName && formik.errors.fatherName ? (
                    <div className="error-message">
                      {formik.errors.fatherName}
                    </div>
                  ) : null}
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Surname*</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="surname"
                  />
                  {formik.touched.surname && formik.errors.surname ? (
                    <div className="error-message">{formik.errors.surname}</div>
                  ) : null}
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Company Name</label>
                  <div className="flex-grow">
                    <Input
                      className="py-0 new-customers-name"
                      clearable
                      classNames={{
                        input: ["bg-transparent", "text-blackone"],
                        innerWrapper: "bg-transparent ",
                        inputWrapper: [
                          "bg-transparent border border border-mutedseven shadow-none py-0",
                          "md:h-11 h-10",
                          "!cursor-text",
                        ],
                      }}
                      type="text"
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="companyName"
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Mobile Number*</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="mobileNumber"
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div className="error-message">
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Village Name*</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.villageName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="villageName"
                  />
                  {formik.touched.villageName && formik.errors.villageName ? (
                    <div className="error-message">
                      {formik.errors.villageName}
                    </div>
                  ) : null}
                </div>

                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Dalal Name</label>
                  <Input
                    className="py-0 new-customers-name"
                    clearable
                    classNames={{
                      input: ["bg-transparent", "text-blackone"],
                      innerWrapper: "bg-transparent ",
                      inputWrapper: [
                        "bg-transparent border border border-mutedseven shadow-none py-0",
                        "md:h-11 h-10",
                        "!cursor-text",
                      ],
                    }}
                    type="text"
                    value={formik.values.agentName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="agentName"
                  />
                  {/* {formik.touched.agentName && formik.errors.agentName ? (
                    <div className="error-message">
                      {formik.errors.agentName}
                    </div>
                  ) : null} */}
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">
                    Dalal Mobile Number
                  </label>
                  <div className="flex-grow">
                    <Input
                      className="py-0 new-customers-name"
                      clearable
                      classNames={{
                        input: ["bg-transparent", "text-blackone"],
                        innerWrapper: "bg-transparent ",
                        inputWrapper: [
                          "bg-transparent border border border-mutedseven shadow-none py-0",
                          "md:h-11 h-10",
                          "!cursor-text",
                        ],
                      }}
                      type="number"
                      value={formik.values.agentMobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="agentMobile"
                    />
                    {formik.touched.agentMobile && formik.errors.agentMobile ? (
                      <div className="error-message">
                        {formik.errors.agentMobile}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Password*</label>
                  <div className="flex-grow">
                    <Input
                      className="py-0 new-customers-name"
                      clearable
                      classNames={{
                        input: ["bg-transparent", "text-blackone"],
                        innerWrapper: "bg-transparent ",
                        inputWrapper: [
                          "bg-transparent border border border-mutedseven shadow-none py-0",
                          "md:h-11 h-10",
                          "!cursor-text",
                        ],
                      }}
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isVisible ? (
                            <Eye size={24} color="#71717A" />
                          ) : (
                            <EyeSlash size={24} color="#71717A" />
                          )}
                        </button>
                      }
                      type={isVisible ? "text" : "password"}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="error-message">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:items-center md:gap-6 gap-2">
                  <label className="whitespace-nowrap ">Customer Photo*</label>
                  <div className="flex items-center gap-3">
                    <div className="relative upload z-0">
                      {imagePreview || croppedImageUrl ? (
                        <Image
                          src={croppedImageUrl ? croppedImageUrl : imagePreview}
                          alt="Profile Preview"
                          className="object-cover"
                          radius="5px"
                          width="100%"
                          height="100%"
                        />
                      ) :
                        <div className="relative">
                          <Image
                            src="/profile-icon/01.webp"
                            alt="Profile Preview"
                            className="object-cover"
                            radius="5px"
                            width="100%"
                            height="100%"
                          />
                          <p className="text-dark-blue absolute top-2/4 start-0 end-0 text-center font-semibold translate-y-[-50%] z-10">Change Photo</p>
                        </div>
                      }
                      <input
                        type="file"
                        className="absolute top-0 bottom-0 start-0 end-0 opacity-0"
                        onChange={(e) => handleFileChange(e)}
                        accept=".png, .jpg, .jpeg"
                        name="profile_photo"
                      />
                      {fileError && (
                        <div className="error-message mt-2">{fileError}</div>
                      )}
                    </div>
                    <div className=" flex flex-col items-center justify-end gap-2">
                      {imagePreview && (
                        <Button isIconOnly className="bg-light-red rounded" onClick={removeProfile} >
                          <Trash size={22} color="#bc0f0f" />
                        </Button>
                      )}

                      {imagePreview && (
                        <Button isIconOnly className="bg-light-blue rounded" onClick={changeProfile} >
                          <Pencil size={22} color="#004f9c" />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="customer-foot ">
                  <div className="lg:px-7.5 px-5 py-5.5 flex items-center justify-end gap-4">
                    <Button className="cancletender_btn">
                      <Link to={`/${status}`}>Cancel</Link>
                    </Button>
                    <Button
                      className="savetender_btn"
                      type="submit"
                      isDisabled={submitLoading}
                    >
                      <div>Update</div>
                      {submitLoading && (
                        <div className="small-loader extra-small-loader ms-4"></div>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
              {/* <img src={croppedImageUrl} /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
