import React, { useState, useEffect } from "react";
import { Tabs, Tab, Input, Image, Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import OpenTendersTable from "./Tabs/OpenTendersTable";
import axios from "axios";
import "./Opentender.scss";

export default function OpenTender() {
  const [selected, setSelected] = React.useState("all");
  const [tenders, setTenders] = useState([]);
  const [loading, setLoading] = useState(true); 

  const fetchTenders = async (tenderType) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/${tenderType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
          },
        }
      );

      const fetchedTenders = response.data;
      setTenders(fetchedTenders);
    } catch (error) {
      console.error("Error fetching tenders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setSelected(tab);
    const tenderTypeMap = {
      all: "all",
      active: "active",
      expired: "expired",
      closed: "closed",
    };

    if (tab === "all") {
      fetchTenders("all");
    } else {
      const type = tenderTypeMap[tab] || tenderTypeMap;
      fetchTenders(type);
    }
  };

  useEffect(() => {
    fetchTenders("all");
  }, []);
  // Opened a new page on the Onclick event
  const navigate = useNavigate();
  const newtender = () => {
    navigate("/open-tender/new-tender");
  };

  const handleSetTender = (data) => {
    setTenders(data);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTenders, setFilteredTenders] = useState(tenders);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredTenders(tenders);
    } else {
      // Filter the tenders based on the search term
      const filtered = tenders.filter(
        (tender) =>
          tender.tenderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tender.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredTenders(filtered);
    }
  }, [tenders, searchTerm]);

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
      <div className="body-conetnt-header border-b-2 border-grayone md:sticky md:top-0 bg-white z-10">
        <div className="flex flex-wrap gap-5 items-center justify-between ">
          <div>
            <Tabs
              aria-label="Options"
              className="tendertabs"
              selectedKey={selected}
              onSelectionChange={handleTabChange}
              classNames={{
                tabList:
                  "relative gap-4.25 xs:flex-nowrap flex-wrap rounded-none p-0 bg-transparent",
                cursor: "w-full bg-light-skyblue",
                tab: "px-4 bg-mutedfive h-9.5 ",
                tabContent:
                  "group-data-[selected=true]:text-blue text-mutedsix  font-semibold text-sm leading-4.5.25",
              }}
            >
              <Tab key="all" title={<div>All Tenders</div>} />
              <Tab key="active" title={<div>Active Tenders</div>} />
              <Tab key="expired" title={<div>Expired Tenders</div>} />
            </Tabs>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap items-center gap-5">
            <Input
              classNames={{
                input: [
                  "placeholder:text-xs placeholder:text-mutedone text-mutedone leading-4 p-0 outline-none",
                ],
                inputWrapper: [
                  "bg-transparent shadow-none border border-graythree h-9.5 min-w-72.5 !cursor-text py-0 px-4.5",
                ],
              }}
              placeholder="Search by Tender ID, Name..."
              startContent={
                <Image
                  alt="Image Search Icon"
                  src="/header-img/icons/search.svg"
                />
              }
              value={searchTerm}
              onChange={updateSearchTerm}
            />

            <Button
              onClick={newtender}
              className="min-h-9.5 rounded-2.5 px-0 min-w-35 add_btn "
            >
              <p className="leading-5.5 font-medium text-white">Add Tender</p>
            </Button>
          </div>
        </div>
      </div>

      <div className="body-tabs-content h-full">
        <Tabs
          aria-label="Options"
          selectedKey={selected}
          onSelectionChange={setSelected}
        >
          <Tab key="all" className="p-0 ">
            <OpenTendersTable
              tenders={filteredTenders}
              selectedTab={selected}
              handleSetTender={handleSetTender}
              tendertype="all"
            />
          </Tab>
          <Tab key="active" className="p-0 ">
            <OpenTendersTable
              tenders={filteredTenders}
              selectedTab={selected}
              handleSetTender={handleSetTender}
              tendertype="active"
            />
          </Tab>
          <Tab key="expired" className="p-0 ">
            <OpenTendersTable
              tenders={filteredTenders}
              selectedTab={selected}
              handleSetTender={handleSetTender}
              tendertype="expired"
            />
          </Tab>
        </Tabs>
      </div>
      </>
      )}
    </>
  );
}
