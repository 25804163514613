import React from 'react'
import { Modal, ModalContent, Button, useDisclosure } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';

export default function ConfirmationTenderModal({ isOpen, onClose, action, currentTender, confirmStatusChange }) {
    const actionText = action === 'delete' ? 'delete' : action === 'expire' ? 'expire' : 'close';

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" hideCloseButton classNames={{ backdrop: "bg-blacktwo", }}>
            <ModalContent>
                <div className="p-7">
                    <h5 className="text-xl font-semibold leading-6.25 text-dark">Confirmation</h5>
                    <p className="text-3.75 text-dark leading-5 mt-6">
                        Are you sure you want to {actionText} this tender?
                    </p>
                    <div className="mt-8 flex items-center justify-end gap-3 moadl_btns">
                        <Button onClick={onClose} className="cancletender_btn">
                            Cancel
                        </Button>
                        <Button className="savetender_btn" onClick={() => {
                            confirmStatusChange(currentTender._id, action);
                            onClose(); // Close the modal
                            // Optionally navigate if needed
                        }}>
                            {actionText?.charAt(0)?.toUpperCase() + actionText?.slice(1)} Tender
                        </Button>
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );
}
