import {
  CREATE_TENDER,
  EDIT_TENDER,
  EDIT_TENDER_LOTS,
  GET_ACTIVE_TENDER,
  GET_ALL_USER_BIDS,
  GET_TENDER_BY_ID,
  MARK_BID_AS_WON,
  SET_TENDERS,
} from '../actions/actionTypes';

const initialState = {
  // Initial state properties if any
  activeTenders: [],
  tenders: [], // Initialize tenders array
  // ... other initial state properties
};

const tenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TENDER:
      return {
        ...state,
        // Handle state changes for tender creation
      };
    case GET_ACTIVE_TENDER:
      return {
        ...state,
        activeTenders: action.payload,
        // ... other state changes for fetching active tenders
      };
    case EDIT_TENDER:
      return {
        ...state,
        // Handle state changes for editing tender
      };

    case 'UPDATE_TENDER':
      const { tenderId, lots } = action.payload;

      // Update the lots for the specified tender
      return {
        ...state,
        tenders: state.tenders.map((tender) =>
          tender._id === tenderId ? { ...tender, lots } : tender
        ),
      };

    case GET_TENDER_BY_ID:
      return {
        ...state,
        // Handle state changes for getting tender by ID
      };
    case GET_ALL_USER_BIDS:
      return {
        ...state,
        // Handle state changes for getting all user bids
      };
    case EDIT_TENDER_LOTS:
      return {
        ...state,
        // Handle state changes for editing tender lots
      };

    case SET_TENDERS: // Add case for SET_TENDERS
      return {
        ...state,
        tenders: action.payload,
      };
    case MARK_BID_AS_WON:
      return {
        ...state,
        // Handle state changes for marking bid as won
      };
    // ... other cases for additional actions
    default:
      return state;
  }
};

export default tenderReducer;
