import { Button, Input, Modal, ModalContent } from "@nextui-org/react";
import { ArrowLeft, Eye, EyeSlash, X } from "@phosphor-icons/react";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Changepassword.scss";



export default function Changepassword({ isOpen, onClose, currentCustomerId }) {
    const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);
    const [isRetypePasswordVisible, setIsRetypePasswordVisible] = React.useState(false);
    const token = localStorage.getItem("token");

    const [formData, setFormData] = useState({
        newPassword: '',
        retypePassword: '',
    });
    console.log(currentCustomerId)
    

    const toggleNewPasswordVisibility = () => setIsNewPasswordVisible(!isNewPasswordVisible);
    const toggleRetypePasswordVisibility = () => setIsRetypePasswordVisible(!isRetypePasswordVisible);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(e)
        setFormData({ ...formData, [name]: value });
    };

    const resetForm = () => {
        setFormData({
          newPassword: '',
          retypePassword: '',
        });
      };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("form : ", currentCustomerId)

        // You can add validation here to ensure new password matches retype password, etc.

        // Send a request to your backend to change the password
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/changePassword/${currentCustomerId}`, {
                method: 'POST',
                headers: {

                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Password changed successfully, you can handle this as needed
                console.log('Password changed successfully');
                onClose(); // Close the modal or navigate away
                resetForm();
            } else {
                // Handle error response from your backend
                console.error('Password change failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (
        <>
            <div className="">
                <Modal
                    isOpen={isOpen}
                    size="xl"
                    onClose={onClose}
                    // backdrop="opaque"
                    radius="none"
                    hideCloseButton
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-blacktwo",
                        base: "m-0",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                    className="absolute top-0 end-0 bottom-0"
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <div className="changep-modal-content overflow-auto h-full">
                                    <div className="body-conetnt-header flex flex-col justify-between ">
                                        <div >
                                            <div className="flex sm:items-center items-starts justify-between gap-5">
                                                <div className="flex sm:flex-row flex-col sm:items-center justify-between gap-5 flex-grow ">
                                                    <div className="flex items-center gap-7 ">
                                                        <Link to="" onClick={onClose} className="back_arrow">
                                                            <ArrowLeft size={24} weight="bold" />
                                                        </Link>
                                                        <h5 className="text-xl leading-8.25 text-blackone font-semibold">
                                                            Change Password
                                                        </h5>
                                                    </div>
                                                </div>

                                                <Link to="" onClick={onClose} className="cancle_arrow">
                                                    <X size={24} color="#7e7e7e" weight="bold" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div className='flex-grow inline-grid items-center mt-16'>
                                            <form action='' className='mx-0 customs_inputs ' onSubmit={handleSubmit}>
                                                <div className='w-full'>
                                                    <Input
                                                        name="newPassword"
                                                        value={formData.newPassword}
                                                        onChange={handleInputChange}
                                                        label={
                                                            <>
                                                                <div className=' text-dark-blue sm:text-base text-sm font-medium'>Enter New Password</div>
                                                            </>
                                                        }
                                                        placeholder="Enter Your Password"
                                                        labelPlacement="outside"
                                                        classNames={{
                                                            innerWrapper: "bg-transparent text-dark-blue",
                                                            inputWrapper: [
                                                                "bg-transparent shadow-none px-4 py-0",
                                                                "md:h-12 h-10",
                                                                "!cursor-text",
                                                            ],

                                                        }}
                                                        endContent={
                                                            <button className="focus:outline-none" type="button" onClick={toggleNewPasswordVisibility}>
                                                                {isNewPasswordVisible ? <Eye size={24} /> : <EyeSlash size={24} />}
                                                            </button>
                                                        }
                                                        type={isNewPasswordVisible ? "text" : "password"}
                                                    />
                                                </div>

                                                <div className='w-full'>
                                                    <Input
                                                        name="retypePassword"
                                                        value={formData.retypePassword}
                                                        onChange={handleInputChange}
                                                        label={
                                                            <>
                                                                <div className=' text-dark-blue sm:text-base text-sm font-medium'>Re-type Password</div>
                                                            </>
                                                        }
                                                        placeholder="Re-type Your Password"
                                                        labelPlacement="outside"
                                                        classNames={{
                                                            innerWrapper: "bg-transparent text-dark-blue",
                                                            inputWrapper: [
                                                                "bg-transparent shadow-none px-4 py-0",
                                                                "md:h-12 h-10",
                                                                "!cursor-text",
                                                            ],

                                                        }}

                                                        endContent={
                                                            <button className="focus:outline-none" type="button" onClick={toggleRetypePasswordVisibility}>
                                                                {isRetypePasswordVisible ? <Eye size={24} /> : <EyeSlash size={24} />}
                                                            </button>
                                                        }
                                                        type={isRetypePasswordVisible ? "text" : "password"}
                                                    />
                                                </div>
                                                <div className='text-center'>
                                                    <Button type='submit' className='form_btn'>
                                                        Save
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                </div>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </div>
        </>
    );
}
