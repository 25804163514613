import React, { useState, useMemo, useRef } from 'react'
import { Modal, ModalContent, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell,Pagination } from "@nextui-org/react";
import { X, ArrowLeft, CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { Link } from 'react-router-dom';
import '.././style.scss'

export default function Bidsdetails({ isOpen, onOpenChange, selectedTender }) {
  // selectedId,

  const topScroll = useRef(null)

  // Table Pagination 
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const pages = Math.ceil(selectedTender.length / rowsPerPage);
  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return selectedTender.slice(start, end);
  }, [page, rowsPerPage]);
  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };

  return (

    <div className=''>
      {console.log("selected : ", selectedTender)}
      <Modal
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
        // backdrop="opaque"
        radius="none"
        hideCloseButton
        classNames={{
          body: "py-6",
          backdrop: "bg-blacktwo",
          base: "m-0",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }} className='absolute top-0 end-0 bottom-0'>
        <ModalContent>
          {(onClose) => (
            <>
              <div className='brid-modal-content h-full'>
                <div className='body-conetnt-header '>
                  <div>
                    <div className='flex sm:items-center items-starts justify-between gap-5'>
                      <div className='flex sm:flex-row flex-col sm:items-center justify-between gap-5 flex-grow '>
                        <div className='flex items-center gap-7 '>
                          <Link to="" onClick={onClose} className='back_arrow'>
                            <ArrowLeft size={24} weight="bold" />
                          </Link>
                          <h5 className='text-xl leading-8.25 text-blackone font-semibold'>Lots</h5>
                        </div>
                      </div>

                      <Link to="" onClick={onClose} className='cancle_arrow'>
                        <X size={24} color="#7e7e7e" weight="bold" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className=' pt-12 h-full'>
                  <h6 className='text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block'>
                    {console.log()}
                    <p className='mb-2.5'>Lots ({selectedTender.length})</p>
                  </h6>
                  <div className='h-full flex flex-col justify-between'>
                    <div ref={topScroll} className='overflow-auto lg:pb-20 lg:mb-2'>
                      <Table radius="none" isHeaderSticky removeWrapper
                        className="custom-table border-t-2 border-graytwo"
                        aria-label="Example static collection table">

                        <TableHeader>
                          <TableColumn>LOT NO.</TableColumn>
                          <TableColumn>DESCRIPTION</TableColumn>
                          <TableColumn>SIZE</TableColumn>
                          <TableColumn>NO. OF STONE</TableColumn>
                          <TableColumn>TOTAL CARAT</TableColumn>
                          <TableColumn>BID VALUE ($/Ct)</TableColumn>
                          <TableColumn>TOTAL VALUE</TableColumn>
                          <TableColumn>STATUS</TableColumn>
                        </TableHeader>
                        <TableBody>
                          {items?.sort((a, b) => a.lot_num.localeCompare(b.lot_num))?.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num))?.map((lot, index) => (
                            <TableRow key={lot._id}>
                              <TableCell className=''>
                                <div>{lot.lot_num}</div>
                              </TableCell>
                              <TableCell>
                                <div>{lot.description}</div>
                              </TableCell>
                              <TableCell>
                                <div>{lot.size}</div>
                              </TableCell>
                              <TableCell>
                                <div>{lot.no_stone}</div>
                              </TableCell>
                              <TableCell>
                                <div>{new Intl.NumberFormat('en-IN').format(lot.total_carat)}</div>
                              </TableCell>
                              <TableCell>
                                <div>${new Intl.NumberFormat('en-IN').format(lot.highestBid)}</div>
                              </TableCell>
                              <TableCell>
                                <div>${new Intl.NumberFormat('en-IN').format((lot.total_carat * lot.highestBid).toFixed(2))}</div>
                              </TableCell>
                              <TableCell>
                                {lot.status === 'done' && <div className='text-green font-semibold uppercase'>{lot.status}</div>}
                                {lot.status === 'rejected' && <div className='text-tometo font-semibold uppercase'>{lot.status}</div>}
                                {lot.status === 'withdraw' && <div className='text-mutedone font-semibold uppercase'>{lot.status}</div>}
                                {lot.status === 'pending' && <div className='text-orange font-semibold uppercase'>{lot.status}</div>}
                              </TableCell>
                            </TableRow>
                          ))}

                        </TableBody>
                      </Table>
                    </div>
                    {/* Pagination */}
                    { items && items.length ? <div className='lg:px-7.5 px-5 modal_paginations'>
                      <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
                        <div className='flex items-center gap-2'>
                          <p className='text-darkone text-sm leading-4.5.25 font-medium'>Page size</p>
                          <select
                            className='focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer'
                            onChange={handlePageSizeChange}
                            value={rowsPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <Pagination
                          // isCompact
                          showControls
                          total={pages || 1}
                          page={page}
                          // color="warning"
                          classNames={{
                            wrapper: "rounded ",
                            item: "rounded ",
                            cursor:
                              "bg-dark-blue  text-white rounded",
                          }}

                          initialPage={1}
                          onChange={(newPage) => {
                            setPage(newPage);
                            topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                          }}
                        />
                      </div>
                    </div> : null}

                  </div>

                </div>

              </div>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
