import React, { useEffect, useState, createContext, useContext } from "react";
import { Image } from "@nextui-org/react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./Sidebar.scss";
import { toast } from "react-toastify";

export const CustomerUpdateContext = createContext({
  isUpdated: false,
  setIsUpdated: () => { },
});
export const CustomerUpdateProvider = ({ children }) => {
  const [isUpdated, setIsUpdated] = useState(true);

  // Provide any additional functions that manipulate isUpdated here, if needed

  return (
    <CustomerUpdateContext.Provider value={{ isUpdated, setIsUpdated }}>
      {children}
    </CustomerUpdateContext.Provider>
  );
};

export default function Sidebar({ toggleSidebar }) {
  // const { tenderId } = useParams();
  // console.log('adddd',)
  const [customers, setCustomers] = useState([]);
  const { isUpdated, setIsUpdated } = useContext(CustomerUpdateContext);
  //const [isUpdated, setIsUpdated] = useState(Boolean);
  const { pathname } = useLocation();
  //console.log("Hstory : ",pathname)

  useEffect(() => {
    // Fetch customers when the component mounts

    fetchCustomers();
  }, [isUpdated, pathname]);

  const fetchCustomers = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomers`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
        },
      });
      const data = await response.json();
      setCustomers(data);
      setIsUpdated(false);
      //
    } catch (error) {
      console.error("Error fetching customers:", error);
      setIsUpdated(false);
    }
  };

  const loc = useLocation();
  console.log(loc);
  const openTenderRegex = /^\/open-tender\/[aen].*$/;
  const needAppRegex = /^\/pending(?:\/.*)?$/;
  const activeCustomerRegex = /^\/approved(?:\/.*)?$/;
  const inactiveCustomerRegex = /^\/rejected(?:\/.*)?$/;
  const inqueuetendersRegex = /^\/open-tender\/inqueue/;
  const closetendersRegex = /^\/open-tender\/closed/;
  // const opentenderRegex = /^\/rejected(?:\/.*)?$/;
  // const closetenderRegex = /^\/rejected(?:\/.*)?$/;
  // Test the pathname and determine the class name

  const dashboardbg =
    loc.pathname === "/dashboard" ? "active-link" : "defaultText";

  const needapprovalbg = needAppRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";
  const activecustomerbg = activeCustomerRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";
  const inactivecustomerbg = inactiveCustomerRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";
  //const tenderbg = loc.pathname === '/open-tender/all' ? 'active-link' : 'defaultText';
  const tenderbg = openTenderRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";
  const inquetenderbg = inqueuetendersRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";
  const closetenderbg = closetendersRegex.test(loc.pathname)
    ? "active-link"
    : "defaultText";

  return (
    <>
      <aside className="bg-dark-blue sidebar overflow-auto">
        <div className="flex flex-col gap-3">
          <Link
            to="/dashboard"
            className={`flex items-center gap-3.5.25 ps-7 hover:opacity-100 py-4 custom-hov ${dashboardbg}`} onClick={toggleSidebar}
          >
            <div>
              <Image
                alt="Dashboard Image Icon"
                src="/sidebar-img/dashboard.svg"
                className="rounded-none"
              />
            </div>
            <p className="text-base leading-5.5 text-light-white">Dashboard</p>
          </Link>
          <div className="flex flex-col gap-2">
            <Link
              to="#"
              className="flex items-center gap-3.5.25 ps-7 hover:opacity-100 py-4 cursor-default"
              onClick={toggleSidebar}
            >
              <div>
                <Image
                  alt="Customers Image Icon"
                  src="/sidebar-img/customer.svg"
                  className="rounded-none"
                />
              </div>
              <p className="text-base leading-5.5 text-light-white">
                Customers
              </p>
            </Link>
            <ul className="nav-list">
              <li className={`custom-hov ${needapprovalbg}`} onClick={toggleSidebar}>
                <Link
                  to="/pending"
                  className="w-full text-sm text-light-white font-normal"
                  name="pending"
                >
                  Need Approval (
                  {customers.filter((c) => c.status === "pending").length})
                </Link>
              </li>
              <li className={`custom-hov ${activecustomerbg}`} onClick={toggleSidebar}>
                <Link
                  to="/approved"
                  className="w-full text-sm text-light-white font-normal"
                >
                  Active Customer (
                  {customers.filter((c) => c.status === "approved").length})
                </Link>
              </li>
              <li className={`custom-hov ${inactivecustomerbg}`} onClick={toggleSidebar}>
                <Link
                  to="/rejected"
                  className="w-full text-sm text-light-white font-normal"
                >
                  Inactive Customer (
                  {customers.filter((c) => c.status === "rejected").length})
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <Link
              to="#"
              className="flex items-center gap-3.5.25 ps-7 hover:opacity-100 py-4 cursor-default"
              onClick={toggleSidebar}
            >
              <div>
                <Image
                  alt="Tenders Image Icon"
                  src="/sidebar-img/tenders.svg"
                  className="rounded-none"
                />
              </div>
              <p className="text-base leading-5.5 text-light-white">Tenders</p>
            </Link>
            <ul className="nav-list">
              <li className={`custom-hov ${inquetenderbg}`} onClick={toggleSidebar}>
                <Link
                  to="/open-tender/inqueue"
                  className="w-full text-sm text-light-white font-normal "
                >
                  InQueue Tenders
                </Link>
              </li>
              <li className={`custom-hov ${tenderbg}`} onClick={toggleSidebar}>
                <Link
                  to="/open-tender/all"
                  className="w-full text-sm text-light-white font-normal"
                >
                  Open Tenders
                </Link>
              </li>
              <li className={`custom-hov ${closetenderbg}`} onClick={toggleSidebar}>
                <Link
                  to="/open-tender/closed"
                  className="w-full text-sm text-light-white font-normal"
                >
                  Closed Tenders
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
