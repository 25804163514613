// actionTypes.js
export const CREATE_TENDER = 'CREATE_TENDER';
export const GET_ACTIVE_TENDER = 'GET_ACTIVE_TENDER';
export const EDIT_TENDER = 'EDIT_TENDER';
export const SET_TENDERS = 'SET_TENDERS';
export const UPDATE_TENDER = 'UPDATE_TENDER';
export const GET_TENDER_BY_ID = 'GET_TENDER_BY_ID';
export const CREATE_BID = 'CREATE_BID';
export const GET_USER_BIDS = 'GET_USER_BIDS';
export const GET_ALL_USER_BIDS = 'GET_ALL_USER_BIDS';
export const EDIT_TENDER_LOTS = 'EDIT_TENDER_LOTS';
export const MARK_BID_AS_WON = 'MARK_BID_AS_WON';
export const SAVE_TENDER_SUCCESS = 'SAVE_TENDER_SUCCESS';
export const SAVE_TENDER_FAILURE = 'SAVE_TENDER_FAILURE';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';



