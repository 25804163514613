import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Image, Modal, ModalContent, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Pagination } from "@nextui-org/react";
import { ArrowLeft, CaretDoubleLeft, CaretDoubleRight, CaretDown, CaretLeft, CaretRight, X } from "@phosphor-icons/react";
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Changepassword from '../components/Changepassword';
import "./Viewtender.scss";
import * as XLSX from 'xlsx';



export default function Customerbriddetails({
  isOpen,
  onOpenChange,
  selectedLot,
  tenderData,
  wonBids,
  handlechangeSetWonBid
}) {
  // Table Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [customers, setCustomers] = useState([]);
  const pages = Math.ceil(customers?.userBids?.length / rowsPerPage);
  const [loading, setLoading] = useState(true);

  const topScroll = useRef(null)


  // const fetchData = async (tenderId) => {
  //     try {
  //         console.log('Fetching tender data...');
  //         const response = await fetch(`${process.env.REACT_APP_API_URL}/tender/lots/${tenderId}`, {
  //             headers: {
  //                 Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTliY2M1YmVmMWEzMDM0ZDE0MzI0NzEiLCJpYXQiOjE3MDUwMzcwNjYsImV4cCI6MTczNjU3MzA2Nn0.zHzQsCAExYKm0CWAQ2c9eOO0afb0pwBr9n6m_pahjgk`,
  //             },
  //         });

  //         if (!response.ok) {
  //             throw new Error(`Failed to fetch tender data: ${response.status} - ${response.statusText}`);
  //         }

  //         const data = await response.json();
  //         console.log('Fetched data:', data);
  //         setTenderData(data);
  //     } catch (error) {
  //         console.error('Error fetching data:', error.message);
  //         // Handle the error or display a message to the user
  //     }
  // };

  // useEffect(() => {
  //     fetchData(tenderId);
  // }, [tenderId]);;
  console.log("wON ", wonBids)

  const markAsWon = async (bidId, status) => {
    try {
      if (!bidId) {
        console.error("Bid ID is undefined");
        return;
      }

      console.log("Marking bid as WON. Bid ID:", bidId);
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tender/bids/${bidId}/mark`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            status,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark bid as WON");
      }
      toast.success('Successfully marked bid as WON')
      fetchCustomers();
      // Refresh data after successful update
    } catch (error) {
      console.error("Error marking bid as WON:", error);
      toast.error(error?.response?.data?.message || 'Error marking bid as WON:');
    }
  };
  useEffect(() => {
    const wonBids = customers?.userBids?.filter((bid) => bid.status === "won");
    handlechangeSetWonBid(wonBids);
  }, [customers]);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tender/lots/${selectedLot?._id}/all-user-bids`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch customer data");
      }

      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };
  const handlePrevPage = () => {
    setPage((currentPage) => Math.max(currentPage - 1, 1));
    topScroll?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleNextPage = () => {
    setPage((currentPage) => Math.min(currentPage + 1, pages));
    topScroll?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleFirstPage = () => {
    setPage(1);
    topScroll?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleLastPage = () => {
    setPage(pages);
    topScroll?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const exportToExcel = (bidsData, fileName) => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(bidsData);

    XLSX.utils.book_append_sheet(wb, ws, "Bid Details");

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleExportClick = () => {
    const dataToExport = customers.userBids.map(bid => ({
      "Customer Name": `${bid.userId.customerName} ${bid.userId.surname}`,
      "Mobile": bid.userId.mobileNumber,
      "Agent": bid.userId.agentName,
      "Bid Value ($/Ct)": bid.price.toFixed(2),
      "Total Value": (bid.price * selectedLot.total_carat).toFixed(2),
      "Status": bid.status
    }));

    exportToExcel(dataToExport, `${tenderData?.name}_${tenderData?.tenderId}_Lot_${selectedLot.lot_num}`);
  };

  // Moadals
  const [isModal2Open, setModal2Open] = useState(false);
  return (
    <>

      <div className="">
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          // backdrop="opaque"
          radius="none"
          hideCloseButton
          classNames={{
            body: "py-6",
            backdrop: "bg-blacktwo",
            base: "m-0 max-w-[1200px]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
          className="absolute top-0 end-0 bottom-0"
        >
          <ModalContent>
            {(onClose) => (
              <>
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <>
                    <div className="brid-modal-content overflow-auto h-full">
                      <div className="body-conetnt-header ">
                        <div>
                          <div className="flex sm:items-center items-starts justify-between gap-5">
                            <div className="flex sm:flex-row flex-col sm:items-center justify-between gap-5 flex-grow ">
                              <div className="flex items-center gap-7 ">
                                <Link to="" onClick={onClose} className="back_arrow">
                                  <ArrowLeft size={24} weight="bold" />
                                </Link>
                                <h5 className="text-xl leading-8.25 text-blackone font-semibold">
                                  Lot Detail
                                </h5>
                              </div>
                              <div className=" z-0">
                                {/* <Dropdown
                              showArrow
                              radius="none"
                              className="border border-grayfive"
                              classNames={{
                                base: "before:bg-white before:border before:border-graythree",
                                content:
                                  "p-0 border border-graythree shadow-none",
                              }}
                            >
                              <DropdownTrigger>
                                <Button className="rounded p-3 flex items-center gap-3.5  border border-grayfive bg-grayeleven">
                                  <p className="text-sm leading-4.5  font-medium text-mutedtwo">
                                    More Action
                                  </p>
                                  <CaretDown
                                    size={14}
                                    color="#838383"
                                    weight="bold"
                                  />
                                </Button>
                              </DropdownTrigger>
                              <DropdownMenu className="z-10">
                                <DropdownSection className="mb-0 ps-3.5 pe-4.5 pb-3.5 pt-4.5">
                                  <DropdownItem className="p-0 rounded-none">
                                    <Link
                                      to=""
                                      className="text-sm leading-4.5.25 text-blackone"
                                    >
                                      View Customer Bids
                                    </Link>
                                  </DropdownItem>
                                  <DropdownItem className="border p-0 border-wheat mt-4 cursor-default"></DropdownItem>

                                  <DropdownItem className="p-0 rounded-none mt-2.75 ">
                                    <div className="text-sm leading-4.5.25 text-darkred">
                                      Mark as Withdraw
                                    </div>
                                  </DropdownItem>
                                </DropdownSection>
                              </DropdownMenu>
                            </Dropdown> */}
                              </div>
                            </div>
                            <div>
                              <Button onClick={handleExportClick}>Export to Excel</Button>
                            </div>

                            <Link to="" onClick={onClose} className="cancle_arrow">
                              <X size={24} color="#7e7e7e" weight="bold" />
                            </Link>
                          </div>
                          <div className="bg-white mt-10 border border-mutedeight rounded-2.5">
                            <div className="lot_detail">
                              <div>
                                <span>LOT NO.</span>
                                <p>{selectedLot?.lot_num}</p>
                              </div>
                              <div>
                                <span>DESCRIPTION</span>
                                <p>{selectedLot?.description}</p>
                              </div>
                              <div className="flex items-start lotdetail_info">
                                <div>
                                  <span>SIZE</span>
                                  <p className="text-end">{selectedLot?.size || '-'}</p>
                                </div>
                                <div>
                                  <span>TOTAL STONE</span>
                                  <p className="text-end">
                                    {selectedLot?.no_stone || '-'}
                                  </p>
                                </div>
                                <div>
                                  <span>TOTAL CARAT</span>
                                  <p className="text-end">
                                    {new Intl.NumberFormat('en-IN').format(selectedLot?.total_carat)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='mt-6'>
                            <h5 className="font-semibold text-4.25 text-darkone leading-6.25">
                              WON Bid Detail
                            </h5>
                            {wonBids && wonBids.length > 0 ? (
                              <div>
                                {wonBids.map((bid, index) => (
                                  <div className="bg-white mt-1 border border-mutedeight rounded-2.5">
                                    <div className="won-content">
                                      <div>
                                        <p> {bid?.userId?.customerName} {bid?.userId?.surname}</p>
                                        <span className="text-sm text-mutednine  font-normal">
                                          {bid?.userId?.villageName}
                                        </span>
                                      </div>
                                      <div>
                                        <p>{bid?.userId?.mobileNumber}</p>
                                      </div>
                                      <div>
                                        <p>{bid?.userId?.agentName}</p>
                                      </div>
                                      <div>
                                        <p>{`$${new Intl.NumberFormat('en-IN').format((bid?.price.toFixed(2)))} / Ct`}</p>
                                      </div>
                                      <div>
                                        <p>{`$${new Intl.NumberFormat('en-IN').format((bid?.price * selectedLot.total_carat).toFixed(2))}`}</p>
                                      </div>
                                      <div>
                                        <p className="won_text">WOn</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="bg-white mt-1 border border-mutedeight rounded-2.5">

                                <div className="won_details justify-center">
                                  <p className="text-center text-3.75 text-darkone font-semibold leading-4">
                                    No any Bid Selected as WON
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                      <div className="pt-5.5">
                        <div>
                          <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
                            <p className="mb-2.5">{`All Bids (${customers?.bidsCount})`}</p>
                          </h6>
                          <div ref={topScroll} className="overflow-auto lg:max-h-[495px] ">
                            <Table
                              radius="none"
                              isHeaderSticky
                              removeWrapper
                              className="custom-table border-t-2 border-graytwo"
                              aria-label="Example static collection table"
                            >
                              <TableHeader>
                                <TableColumn>CUSTOMER NAME</TableColumn>
                                <TableColumn>MOBILE</TableColumn>
                                <TableColumn>AGENT</TableColumn>
                                <TableColumn>BID VALUE ($/Ct)</TableColumn>
                                <TableColumn>TOTAL VALUE</TableColumn>
                                <TableColumn>STATUS</TableColumn>
                                <TableColumn></TableColumn>
                              </TableHeader>
                              <TableBody>
                                {console.log(customers)}
                                {customers?.userBids?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((customer, index) => (


                                  < TableRow TableRow key={customer.key} >
                                    <TableCell className="">
                                      <div>{customer?.userId?.customerName} {customer?.userId?.surname}</div>
                                      <p className="text-sm text-mutednine  font-normal">
                                        {customer?.userId?.villageName}
                                      </p>
                                    </TableCell>
                                    <TableCell>
                                      <div>{customer?.userId?.mobileNumber}</div>
                                    </TableCell>
                                    <TableCell>
                                      <div>{customer?.userId?.agentName}</div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {`$${new Intl.NumberFormat('en-IN').format((customer?.price).toFixed(2))} / Ct`}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div>{`$${new Intl.NumberFormat('en-IN').format((customer?.price * selectedLot.total_carat).toFixed(2))}`}</div>
                                    </TableCell>
                                    <TableCell>
                                      {customer?.status === 'rejected' && <div className="text-redtwo font-semibold uppercase">{customer?.status}</div>}
                                      {customer?.status === 'won' && <div className="text-green font-semibold uppercase">{customer?.status}</div>}
                                      {customer?.status === 'pending' && <div className="text-orange font-semibold uppercase">{customer?.status}</div>}
                                      {customer?.status === 'withdraw' && <div className="text-graynine font-semibold uppercase">{customer?.status}</div>}
                                      {/* <div className="text-blueone font-semibold">{customer?.status}</div> */}
                                      <div className="text-redtwo font-semibold hidden">{customer?.userId?.status_reject}</div>
                                    </TableCell>
                                    <TableCell className="z-0">
                                      {/* Active Satus DropDown */}
                                      <div className="flex items-center w-4.5 z-0 h-full justify-end">
                                        <Dropdown
                                          placement="bottom-end"
                                          radius="sm"
                                          className="drop-box w.4.5"
                                          classNames={{
                                            content: "p-0 shadow-drop ",
                                          }}
                                        >
                                          <DropdownTrigger>
                                            <Image
                                              alt="Edit Image Icon"
                                              src="/box-img/EditerToggle.svg"
                                              className="rounded-none cursor-pointer"
                                            />
                                          </DropdownTrigger>
                                          <DropdownMenu className="z-10">
                                            <DropdownSection className={`mb-0 ps-3.5 pe-4.5 pb-3.5 ${customer?.status == 'pending' ? 'pt-4.5' : 'pt-3.5'}`}>
                                              {
                                                customer?.status !== 'won' ? <DropdownItem className="p-0 rounded-none">
                                                  <Link
                                                    to=""
                                                    onClick={() =>
                                                      markAsWon(customer._id, "won")
                                                    }
                                                    className="text-sm leading-4.5.25 text-green"
                                                  >
                                                    Mark as WON
                                                  </Link>
                                                </DropdownItem> : <DropdownItem className='hidden'></DropdownItem>
                                              }

                                              {
                                                customer?.status == 'pending' && <DropdownItem className='border p-0 border-wheat my-4 mb-2.75 cursor-default'></DropdownItem>
                                              }
                                              {
                                                customer?.status == 'withdraw' && <DropdownItem className='border p-0 border-wheat my-4 mb-2.75 cursor-default'></DropdownItem>
                                              }

                                              {
                                                customer?.status !== 'rejected' ? <DropdownItem className="p-0 rounded-none ">
                                                  <Link
                                                    to=""
                                                    className="text-sm leading-4.5.25 text-redtwo"
                                                    onClick={() =>
                                                      markAsWon(customer._id, "rejected")
                                                    }
                                                  >
                                                    Mark as Rejected
                                                  </Link>
                                                </DropdownItem> : <DropdownItem className='hidden'></DropdownItem>
                                              }

                                            </DropdownSection>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </div>

                      </div>
                    </div>

                    {/* Pagination */}
                    { customers?.userBids && customers?.userBids.length ? <div className="lg:px-7.5 px-5 modal_paginations">
                      <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
                        <div className="flex items-center gap-2">
                          <p className="text-darkone text-sm leading-4.5.25 font-medium">
                            Page size
                          </p>
                          <select
                            className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                            onChange={handlePageSizeChange}
                            value={rowsPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <Pagination
                          // isCompact
                          showControls
                          total={pages || 1}
                          page={page}
                          // color="warning"
                          classNames={{
                            wrapper: "rounded ",
                            item: "rounded ",
                            cursor:
                              "bg-dark-blue  text-white rounded",
                          }}

                          initialPage={1}
                          onChange={(newPage) => {
                            setPage(newPage);
                            topScroll?.current?.scrollIntoView({ behavior: "smooth" });
                          }}
                        />
                      </div>
                    </div> : null}
                  </>
                )}
              </>
            )}
          </ModalContent>
        </Modal>

        {/* Modlas */}
        <Changepassword isOpen={isModal2Open} onClose={() => setModal2Open(false)} />  {/* Change Password Moadl */}
      </div >

    </>
  );
}