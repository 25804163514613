import React, { useState, createContext } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Dashboard from "./dashboard";
import Login from "./login";
import NeedApproval from "./needapproval";
import NewCustomer from "./newcustomer";
import EditCustomer from "./newcustomer/EditCustomer";
import NewTender from "./newtender";
import InQueueTenders from "./opentender/InQueueTenders";
import OpenTender from "./opentender";
import store from "./redux/store";
import ViewActiveCustomer from "./viewactivecustomer";
import ViewTender from "./viewtender";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditTender from "./newtender/EditTender";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { CustomerUpdateProvider } from "./components/Sidebar";


const MainLayout = ({ toggleResponsiveSidebar, isResponsiveSidebar }) => {
  return (
    <CustomerUpdateProvider>
      <div
        className={`admin-content flex flex-col h-full ${isResponsiveSidebar ? "responsive-sidebar" : ""
          }`}
      >
        <Header toggleResponsiveSidebar={toggleResponsiveSidebar} />
        <div className="flex justify-end main-content">
          <Sidebar toggleSidebar={toggleResponsiveSidebar}
            isResponsiveSidebar={isResponsiveSidebar} />
          <div className="body-content overflow-auto relative flex flex-col">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/:status" element={<NeedApproval />} />
              <Route path="/pending/newcustomer" element={<NewCustomer />} />
              <Route path="/approved/newcustomer" element={<NewCustomer />} />
              <Route path="/:status/editcustomer/:customerId" element={<EditCustomer />} />
              <Route path="/open-tender/all" element={<OpenTender />} />
              <Route
                path="/open-tender/:tendertype"
                element={<InQueueTenders />}
              />
              <Route
                path="/open-tender/:tendertype/edit-tender/:tenderId"
                element={<EditTender />}
              />
              <Route
                path="/open-tender/:tendertype/view-tender/:tenderId"
                element={<ViewTender />}
              />
              <Route path="/open-tender/new-tender" element={<NewTender />} />
              {/* <Route path="/pending/view-active-customer/:customerId" element={<ViewActiveCustomer />} /> */}
              <Route
                path="/:status/view-active-customer/:customerId"
                element={<ViewActiveCustomer />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </CustomerUpdateProvider>
  );
};

export default function App() {
  const [isResponsiveSidebar, setResponsiveSidebar] = useState(false);

  const toggleResponsiveSidebar = () => {
    setResponsiveSidebar(!isResponsiveSidebar);
  };


  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <MainLayout
                    toggleResponsiveSidebar={toggleResponsiveSidebar}
                    isResponsiveSidebar={isResponsiveSidebar}
                  />
                </ProtectedRoute>
              }
            />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </Provider>
    </>
  );
}
