import { combineReducers } from '@reduxjs/toolkit';
import bidReducer from './bidReducer';
import tenderReducer from './tenderReducer';

const rootReducer = combineReducers({
  bid: bidReducer,
  tender: tenderReducer,
  // ... other reducers as needed
});

export default rootReducer;
