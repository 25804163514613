import React from 'react'
import { Image, Input, } from "@nextui-org/react";

export default function Searchinput({searchTerm, setSearchTerm}) {
    const updateSearchTerm = (event) => {
        setSearchTerm(event.target.value);
      };
    return (
        <>
            <Input
                classNames={{
                    input: [
                        "placeholder:text-xs placeholder:text-mutedone text-mutedone leading-4 p-0 outline-none h-full",
                    ],
                    inputWrapper: [
                        "bg-transparent shadow-none border border-graythree h-9.5 min-w-72.5 !cursor-text py-0 pb-0 px-4.5",
                    ],
                }}
                placeholder="Search by Customer name, mobile..."
                startContent={
                    <Image
                        alt="Image Search Icon"
                        className="h-full search-icon z-0"
                        src="/header-img/icons/search.svg"
                    />
                }
                className="inner-wrapper"
                value = {searchTerm}
                onChange={updateSearchTerm}
            />
        </>
    )
}
