import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_URL}`; // Adjust the base URL according to your server setup

export const createTenderService = async (tenderData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/tender`, tenderData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveTenderService = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tender/active`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenderService = async (tenderId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/tender/${tenderId}`, updatedData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTenderByIdService = async (tenderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tender/${tenderId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUserBidsService = async (lotId, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tender/lots/${lotId}/all-user-bids`, {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenderLotsService = async (lotId, updatedFields) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/tender/lots/${lotId}`, updatedFields);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markBidAsWonService = async (bidId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/tender/bids/${bidId}/mark`, { status: 'won' });
    return response.data;
  } catch (error) {
    throw error;
  }
};

