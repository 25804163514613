import React, { useState, useEffect } from "react";
import { Tabs, Tab, Input, Image, Button } from "@nextui-org/react";
import OpenTendersTable from "./Tabs/OpenTendersTable";
import axios from "axios";
import "./Opentender.scss";
import { useNavigate, useParams } from "react-router-dom";
import { boolean } from "yup";

const TENDER_STATUSES = ["inque", "active", "expired"];

export default function InQueueTenders() {
  const [selected, setSelected] = useState();
  const [tenders, setTenders] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const { tendertype } = useParams();

  const fetchTenders = async (status) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/${tendertype}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
          },
        }
      );

      const fetchedTenders = response.data;
      setTenders(fetchedTenders);
      // console.log(`Fetched ${status} tenders:`, fetchedTenders);
    } catch (error) {
      console.error(`Error fetching ${status} tenders:`, error);
      // Handle error, show a message, etc.
    }
  };

  const handleTabChange = (tab) => {
    setSelected(tab);
    fetchTenders(tab);
  };


  useEffect(() => {
    // Fetch tenders when the component mounts or when the selected tab changes
    fetchTenders(selected);
  }, [selected, tendertype]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTenders, setFilteredTenders] = useState(tenders);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredTenders(tenders);
    } else {
      // Filter the tenders based on the search term
      const filtered = tenders.filter(
        (tender) =>
          tender.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tender._id.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredTenders(filtered);
    }
  }, [tenders, searchTerm]);

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSetTender = (data) => {
    setTenders(data);
  };

  const titleBasedOnStatus = {
    inqueue: "Tenders - Inqueue",
    default: "Tenders",
    closed: "Tenders - Closed",
  };

  return (
    <>

      <div className="body-conetnt-header border-b-2 border-grayone md:sticky md:top-0 bg-white z-10">
        <div className="flex flex-wrap gap-5 items-center justify-between">
          <div>
            <h5 className="text-lg leading-8.25 text-blackone font-semibold">
              {titleBasedOnStatus[tendertype]} ({tenders.length})
              {/* Tender - Inqueue */}
            </h5>
          </div>

          <div>
            <Tabs
              aria-label="Options"
              selectedKey={selected}
              onSelectionChange={handleTabChange}
              classNames={{
                tabList:
                  "relative gap-4.25 xs:flex-nowrap flex-wrap rounded-none p-0 bg-transparent",
                cursor: "w-full bg-light-skyblue",
                tab: "rounded-2.5 px-4 bg-mutedfive h-9.5 ",
                tabContent:
                  "group-data-[selected=true]:text-blue text-mutedsix  font-semibold text-sm leading-4.5.25",
              }}
            >
              {TENDER_STATUSES.map(
                (status) =>
                  status === selected && (
                    <Tab
                      key={status}
                      title={
                        <div>{`${status?.charAt(0)?.toUpperCase()}${status?.slice(
                          1
                        )} Tenders`}</div>
                      }
                    />
                  )
              )}
            </Tabs>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap items-center gap-5">
            <Input
              classNames={{
                input: [
                  "placeholder:text-xs placeholder:text-mutedone text-mutedone leading-4 p-0 outline-none",
                ],
                inputWrapper: [
                  "bg-transparent shadow-none border border-graythree h-9.5 min-w-72.5 !cursor-text py-0 px-4.5",
                ],
              }}
              placeholder="Search by Tender ID, Name..."
              startContent={
                <Image
                  alt="Image Search Icon"
                  src="/header-img/icons/search.svg"
                />
              }
              value={searchTerm}
              onChange={updateSearchTerm}
            />
          </div>
        </div>
      </div>
  
      <div className="body-tabs-content">
        <OpenTendersTable
          tenders={filteredTenders}
          selectedTab={selected}
          tendertype={tendertype}
          handleSetTender={handleSetTender}
        />
      </div>
    </>
  );
}
