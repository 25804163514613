import { Button, Image, Input } from "@nextui-org/react";
import { Phone, Eye, EyeSlash, EnvelopeSimple } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.scss";

export default function Login() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  //const [email, setEmail] = useState('');
  // const [otp, setOtp] = useState('');
  const [password, setPassword] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = Boolean(localStorage.getItem('token'));
    if (isAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setSubmitLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobileNumber, password, isAdmin: true }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Handle successful OTP verification

        // Store the token in local storage
        localStorage.setItem("token", data.token);
        localStorage.setItem("name", data.username);
        localStorage.setItem("photo", data.profile_photo);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("surname", data.surname);

        // Redirect or perform additional actions as needed
        setSubmitLoading(false);
        // navigate("/dashboard");
        navigate('/dashboard', { replace: true });
      } else {
        // Handle unsuccessful OTP verification
        console.error("Password verification failed");
        toast.error(data?.message || "An error occurred during login.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="login_bg ">
        <div className="grid grid-cols-12 mx-4 login-content">
          <div className="lg:col-span-5 col-span-12">
            <div className="login_image">
              <Image
                alt="Login Image"
                src="/login-img/logo.svg"
                className="ronded-none mx-w-full max-h-full "
              />
            </div>
          </div>
          <div className="lg:col-span-7 col-span-12">
            <h1 className="text-dark-blue font-bold  text-center">
              Admin Panel
            </h1>
            <h4 className="text-dark-blue font-normal pt-2 text-center text-2xl">
              Login to your account
            </h4>
            <form action="" className="sm:mt-14 mt-8 mx-auto customs_inputs">
              <div>
                <Input
                  type="tel"
                  label={
                    <>
                      <div className="uppercase text-dark-blue sm:text-base text-sm font-medium">
                        MOBILE NUMBER
                      </div>
                      {/* <div className='uppercase text-dark-blue sm:text-base text-sm font-medium'>Email Id</div> */}
                    </>
                  }
                  placeholder="Enter Your Mobile Number"
                  //placeholder="Enter Your Email Id"
                  labelPlacement="outside"
                  value={mobileNumber}
                  //value={email}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  classNames={{
                    innerWrapper: "bg-transparent text-dark-blue",
                    inputWrapper: [
                      "bg-transparent shadow-none px-4 py-0",
                      "md:h-12 h-10",
                      "!cursor-text",
                    ],
                  }}
                  endContent={<Phone size={24} color="#71717A" />}
                />
              </div>

              <div>
                <Input
                  label={
                    <>
                      <div className="uppercase text-dark-blue sm:text-base text-sm font-medium">
                        ENTER Password
                      </div>
                    </>
                  }
                  placeholder="Enter Password"
                  labelPlacement="outside"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  classNames={{
                    innerWrapper: "bg-transparent text-dark-blue",
                    inputWrapper: [
                      "bg-transparent shadow-none px-4 py-0",
                      "md:h-12 h-10",
                      "!cursor-text",
                    ],
                  }}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <Eye size={24} color="#71717A" />
                      ) : (
                        <EyeSlash size={24} color="#71717A" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  className="form_btn"
                  onClick={handleLogin}
                  isDisabled={submitLoading}
                >
                  LOGIN
                  {submitLoading && (
                    <div className="small-loader extra-small-loader ms-4"></div>
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
