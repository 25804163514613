import React from 'react'
import { Modal, ModalContent, Button, useDisclosure } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';

export default function Confirmationmodal({ isOpen, onClose, selectedAction, currentCustomer, confirmStatusChange }) {
    const navigate = useNavigate();
    const action = selectedAction === 'delete' ? 'deleted' : selectedAction === 'approve' ? 'approved' : 'rejected';
    return (
        <>
            {/* Modal */}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
                hideCloseButton
                classNames={{
                    backdrop: "bg-blacktwo",
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <div className="p-7">
                                <h5 className="text-xl font-semibold leading-6.25 text-dark">
                                    Confirmation
                                </h5>
                                <p className="text-3.75 text-dark leading-5 mt-6">
                                    Are you sure, you want to {selectedAction === 'reject'? 'Inactive' : selectedAction} this customer ?
                                </p>
                                <div className="mt-8 flex items-center justify-end gap-3 moadl_btns">
                                    <Button onClick={onClose} className="cancletender_btn">
                                        <p>Cancel</p>
                                    </Button>
                                    <Button className="savetender_btn" onClick={() => {
                                        confirmStatusChange(action);
                                        //navigate(`/${currentCustomer.status}`);
                                        }}>
                                        <div>{selectedAction === 'reject'?'inactive' : selectedAction}</div>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
