import {
  CREATE_BID,
  GET_USER_BIDS,
  MARK_BID_AS_WON,
  SAVE_TENDER_SUCCESS,
  SAVE_TENDER_FAILURE
} from '../actions/actionTypes';

const initialState = {
    data: null,
    error: null,
  // Initial state properties if any
  userBids: [],
  // ... other initial state properties
};

const bidReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BID:
      return {
        ...state,
        // Handle state changes for bid creation
      };
    case GET_USER_BIDS:
      return {
        ...state,
        userBids: action.payload,
        // ... other state changes for fetching user bids
      };
    case MARK_BID_AS_WON:
      return {
        ...state,
        // Handle state changes for marking bid as won
      };
    // ... other cases for additional actions
    default:
      return state;
      case SAVE_TENDER_SUCCESS:
        return {
          ...state,
          data: action.payload,
          error: null,
          // ... other state changes for successful tender save
        };
      case SAVE_TENDER_FAILURE:
        return {
          ...state,
          data: null,
          error: action.payload,
          // ... other state changes for failed tender save
        };
  }
};

export default bidReducer;
