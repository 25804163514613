
import { Modal, ModalContent, ModalHeader, ModalBody, Image } from "@nextui-org/react"


export default function ProfilePhotoModal({ isOpen, onClose, customer}) {


    return (
        
            <Modal closeButton isOpen={isOpen} onClose={onClose}>
                
                <ModalContent>
                    
                        
                            {/* <ModalHeader className="flex flex-col gap-1">Modal Title</ModalHeader>
                                <ModalBody>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam pulvinar risus non risus hendrerit venenatis.
                                        Pellentesque sit amet hendrerit risus, sed porttitor quam.
                                    </p>

                                </ModalBody> */}

                            {customer?.profile_photo? (
                                <Image
                                    src={customer?.profile_photo}
                                    alt="Profile"
                                    width="100%"
                                    height="100%"
                                />
                            ) : (
                                <div className='flex items-center justify-center  bg-wheat text-black font-bold text-9xl' style={{ width: '100%', height: '500px' }}>
                                    {customer?.customerName?.charAt(0)?.toUpperCase()}
                                </div>
                            )}

                        
                    
                </ModalContent>
            </Modal>
        
    )
}