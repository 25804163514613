import { React } from "react"
import { Image, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, Tooltip } from "@nextui-org/react";
import { Link, useNavigate } from 'react-router-dom';
import { SignOut, ListBullets, CaretDown, ArrowClockwise } from "@phosphor-icons/react";
import './header.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export default function Header({ toggleResponsiveSidebar }) {

    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const username = localStorage.getItem('name');
    const profile_photo = localStorage.getItem('photo');
    const surname = localStorage.getItem('surname')

    // if(profile_photo === ''){
    //     profile_photo = '/navbar-img/logo.png'
    // }


    const renderProfilePhotoOrPlaceholder = () => {
        console.log("UserName : ", profile_photo);
        if (profile_photo) {
            return <Image alt="Profile" src={profile_photo} width={32} height={32} className="rounded-full" />;
        } else {
            // If profile_photo is not available, use the first letter of username
            // Adjust the styles as needed
            console.log("UserName : ", profile_photo);
            const firstLetter = username?.charAt(0)?.toUpperCase();
            return (
                <div className='text-black font-bold text-xl' >
                    {firstLetter}
                </div>
            );
        }
    };
    function capitalizeString(string) {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }


    const handleLogout = async () => {
        try {
            // Make a POST request to the server logout endpoint
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                // Successful logout
                // Redirect to the login page
                localStorage.removeItem('page')
                localStorage.removeItem('pageRow')
                localStorage.removeItem('pageKey')
                localStorage.removeItem('token');
                navigate('/login');

            } else {
                console.error('Logout failed:', response.data.message);
                toast.error(error?.response?.data?.message);
            }
        } catch (error) {
            // Handle errors during logout
            console.error('Error during logout:', error);
            toast.error(error?.response?.data?.message);
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    };



    return (
        <>
            <header className=' sticky top-0 start-0 end-0 bg-white z-10'>
                <div className='flex items-center justify-between header'>
                    <div className='flex items-center gap-4'>
                        <ListBullets size={30} color="#004f9c" weight="bold" className='block lg:hidden' onClick={toggleResponsiveSidebar} />
                        <div className='flex items-center gap-4.5' >
                            <Image alt="Navbar Logo" src="/navbar-img/logo.png" />
                            <h2 className='text-dark-blue font-semibold'>K. STAR EXPORT</h2>
                        </div>
                    </div>

                    <div className='cursor-pointer flex gap-3.5  items-center'>
                        <Tooltip showArrow={true} classNames={{ base: ["before:border  before:border-graysix",], }} className="rounded h-6 shadow-none border border-graysix" content={
                            <div div className="text-sm text-blue" > Refresh</div>
                        }>
                            <Button isIconOnly onClick={handleRefresh} className="bg-dark-blue rounded p-0 h-8 w-8">
                                <ArrowClockwise size={18} weight="bold" color="#ffffff" />
                            </Button>
                        </Tooltip>

                        <Dropdown
                            showArrow
                            radius="sm"
                            className='z-0 '
                            classNames={{
                                base: "before:bg-white before:border before:border-graythree", // change arrow background
                                content: "p-0 border border-graythree shadow-none",
                            }}
                        >
                            <DropdownTrigger>
                                <div className='flex items-center gap-3.5'>
                                    <div className='flex items-center gap-2.5.25'>
                                        <div className='flex items-center justify-center profile-icon'>
                                            {/* /<Image alt="Profile" src={profile_photo} width={32} height={32} className="rounded-full" /> */}
                                            {renderProfilePhotoOrPlaceholder()}
                                        </div>
                                        <div className='hidden sm:block'>
                                            <p className='text-3.5 leading-4.5'>{capitalizeString(username)} </p>
                                            <p className='text-xs font-semibold text-purple'>Admin</p>
                                        </div>
                                    </div>
                                    <div className='hidden sm:block'>
                                        <CaretDown size={16} color="#757575" weight="bold" />
                                    </div>
                                </div>
                            </DropdownTrigger>
                            <DropdownMenu
                                aria-label="Custom item styles"
                                className="p-2 z-0"
                                itemClasses={{
                                    base: [
                                        "rounded-md",
                                        "data-[hover=true]:bg-default-100",
                                    ],
                                }} >
                                <DropdownSection aria-label="Profile & Actions" className="mb-0">
                                    <DropdownItem key="profile" className="opacity-100 p-2 outline-none" onClick={handleLogout}>
                                        <Link to="" className='flex items-center gap-2.5'>
                                            <SignOut size={17} weight="bold" color="#3d3b3b" />
                                            <p className='text-darktwo text-sm leading-4'>Sign Out</p>
                                        </Link>
                                    </DropdownItem>
                                </DropdownSection>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div >
            </header >
        </>
    )
}
